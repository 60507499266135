import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconLand = (props: IconProps) => (
  <IconSvg
    height="127.318"
    viewBox="0 0 201.128 127.318"
    width="201.128"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-3179.07 -467.381)">
      <path d="M3378.7,594.7H3180.57a1.5,1.5,0,0,1-1.38-2.088l27.574-64.681a1.5,1.5,0,0,1,1.38-.912H3349.08a1.5,1.5,0,0,1,1.364.876l29.617,64.681a1.5,1.5,0,0,1-1.363,2.124Zm-195.858-3h193.521l-28.243-61.681H3209.136Z" />
      <path d="M3366.6,586.474H3192.47a1.5,1.5,0,0,1-1.38-2.088l20.875-48.966a1.5,1.5,0,0,1,1.38-.913H3344.18a1.5,1.5,0,0,1,1.364.876l22.422,48.967a1.5,1.5,0,0,1-1.364,2.124Zm-171.862-3h169.525l-21.048-45.967H3214.336Z" />
      <g>
        <path
          d="M3279.444,560.361c-1.788.5-38.5-39.618-28.025-69.8s50.2-25.563,57.286,2.464C3315.376,519.417,3287.42,558.137,3279.444,560.361Z"
          fill={color.whiteLoop}
        />
        <path
          d="M3279.356,562.883c-1.17,0-2.518-.9-5.559-4.134-12.3-13.089-33.292-44.356-24.74-69.008,4.994-14.4,17.063-22.964,31.483-22.327a32.864,32.864,0,0,1,30.589,25c6.983,27.628-21.643,67.745-31.013,70.357h0A2.8,2.8,0,0,1,3279.356,562.883Zm.088-2.522h0Zm-.242-87.977c-9.308,0-20.548,4.951-25.421,18.995-4.994,14.395,1.729,31.143,8.248,42.658,6.92,12.221,15.022,21.187,17.655,23.519,7.968-4.474,32.681-39.848,26.6-63.92a27.929,27.929,0,0,0-25.957-21.227C3279.954,472.393,3279.579,472.384,3279.2,472.384Z"
          fill={color.violetLoop}
        />
      </g>
      <path
        d="M3280.2,517.447a18.562,18.562,0,0,1-13.308-6.2,14.73,14.73,0,0,1-3.116-13.592c1.147-4.588,5.213-8.89,10.357-10.958a18.822,18.822,0,0,1,17.422,1.749,14.19,14.19,0,0,1,6.591,15.538c-1.69,7.264-8.2,12.521-16.594,13.4A13.1,13.1,0,0,1,3280.2,517.447Zm.941-27.111a13.88,13.88,0,0,0-5.143,1c-3.649,1.467-6.612,4.495-7.371,7.532a9.612,9.612,0,0,0,2.041,9.1,13.346,13.346,0,0,0,10.366,4.438h0c7.578-.79,11.331-5.642,12.242-9.557a9.2,9.2,0,0,0-4.382-10.172A14.4,14.4,0,0,0,3281.142,490.336Z"
        fill={color.violetLoop}
      />
    </g>
  </IconSvg>
);
