import styled from 'styled-components';
import { Box } from './box';

const Flex = styled(Box)({
  display: 'flex'
});

Flex.displayName = 'Flex';

export { Flex };
