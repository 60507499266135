import styled from 'styled-components';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';
import { Flex } from './flex';

const ButtonContainer = styled(Flex)`
  align-items: center;
  column-gap: 88px;
  margin-top: 60px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column-reverse;
    row-gap: 24px;
  }
`;

export { ButtonContainer };
