import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconTick = (props: IconProps) => (
  <IconSvg
    height="11.715"
    viewBox="0 0 15.256 11.715"
    width="15.256"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7811.038,557.5l5.234,5.489,8.553-9.574"
      fill="none"
      stroke={color.blackLoop}
      strokeMiterlimit="10"
      strokeWidth="2"
      transform="translate(-7810.314 -552.745)"
    />
  </IconSvg>
);
