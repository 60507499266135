import { PropertyOptionsModel } from '../types/property-options';

interface PropertyOptionItem {
  key: PropertyOptionsModel;
  optionalQuestions: Record<string, boolean>;
  propertyOptionTitle: string;
  propertyTypeTitle1: string;
  propertyTypeTitle2?: string;
  statusOptions: string[];
}

const PropertyOptionsObject: Record<PropertyOptionsModel, PropertyOptionItem> = {
  Buy: {
    key: 'Buy',
    optionalQuestions: {
      'I would like pre-contract advice': false,
      'I am looking to get borrowing assistance': false,
      'I am a foreign purchaser': false,
      'I have completed a home inspection service': false,
      'I plan to use a Trust/Super fund to finance the property': false,
      'I am a first home buyer': false
    },
    propertyOptionTitle: 'BUY',
    propertyTypeTitle1: 'I’M BUYING',
    statusOptions: [
      'Buying status*',
      'I have purchased a property',
      'I am in final negotiations',
      'I am considering purchasing',
      'Other'
    ]
  },
  BuyAndSell: {
    key: 'BuyAndSell',
    optionalQuestions: {
      'I would like pre-contract advice': false,
      'I am looking to get borrowing assistance': false,
      'I am a foreign purchaser': false,
      'I have completed a home inspection service': false,
      'I plan to use a Trust/Super fund to finance the property': false,
      'I am a first home buyer': false,
      'I am looking to buy a new home after the sale': false,
      'I am a foreign seller': false
    },
    propertyOptionTitle: 'BUY AND SELL',
    propertyTypeTitle1: 'I’M BUYING',
    propertyTypeTitle2: 'I’M SELLING',
    statusOptions: [
      'Buying and selling status*',
      'I want a company to look after the process',
      'Other'
    ]
  },
  Sell: {
    key: 'Sell',
    optionalQuestions: {
      'I am looking to buy a new home after the sale': false,
      'I am a foreign purchaser': false,
      'I plan to use a Trust/Super fund to finance the property': false
    },
    propertyOptionTitle: 'SELL',
    propertyTypeTitle1: 'I’M SELLING',
    statusOptions: [
      'Selling status*',
      'I have sold a property',
      'I am in final negotiations',
      'I am considering selling',
      'Other'
    ]
  },
  Transfer: {
    key: 'Transfer',
    optionalQuestions: {
      'I am transferring to my spouse or partner': false,
      'I want to transfer to my child or children': false,
      'I plan to transfer to another family member': false,
      'I am not sure how the transfer process works': false,
      'I am interested in the legalities in property transfers': false
    },
    propertyOptionTitle: 'TRANSFER',
    propertyTypeTitle1: '',
    statusOptions: [
      'Transfer status*',
      'I am transferring to my spouse',
      'I am transferring to a family member',
      'Other'
    ]
  }
};

export { PropertyOptionsObject };
