import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconAppartment = (props: IconProps) => (
  <IconSvg
    height="135.098"
    viewBox="0 0 102.995 135.098"
    width="102.995"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-2573.549 -453.935)">
      <path d="M2619.527,515.167h-19.744a1.5,1.5,0,0,1-1.5-1.5V489.412a1.5,1.5,0,0,1,1.5-1.5h19.744a1.5,1.5,0,0,1,1.5,1.5v24.255A1.5,1.5,0,0,1,2619.527,515.167Zm-18.244-3h16.744V490.912h-16.744Z" />
      <path d="M2651.16,515.167h-19.745a1.5,1.5,0,0,1-1.5-1.5V489.412a1.5,1.5,0,0,1,1.5-1.5h19.745a1.5,1.5,0,0,1,1.5,1.5v24.255A1.5,1.5,0,0,1,2651.16,515.167Zm-18.245-3h16.745V490.912h-16.745Z" />
      <path d="M2619.527,564.267h-19.744a1.5,1.5,0,0,1-1.5-1.5V538.512a1.5,1.5,0,0,1,1.5-1.5h19.744a1.5,1.5,0,0,1,1.5,1.5v24.255A1.5,1.5,0,0,1,2619.527,564.267Zm-18.244-3h16.744V540.012h-16.744Z" />
      <path d="M2651.16,564.267h-19.745a1.5,1.5,0,0,1-1.5-1.5V538.512a1.5,1.5,0,0,1,1.5-1.5h19.745a1.5,1.5,0,0,1,1.5,1.5v24.255A1.5,1.5,0,0,1,2651.16,564.267Zm-18.245-3h16.745V540.012h-16.745Z" />
      <path d="M2660.323,589.033h-69.216a7.07,7.07,0,0,1-7.062-7.063V455.433a1.5,1.5,0,0,1,.505-1.122,1.516,1.516,0,0,1,1.175-.367l86.152,10.4a1.5,1.5,0,0,1,1.321,1.489v8.255a1.5,1.5,0,0,1-1.5,1.5h-4.313V581.97A7.07,7.07,0,0,1,2660.323,589.033Zm-73.278-131.907V581.97a4.067,4.067,0,0,0,4.062,4.063h69.216a4.067,4.067,0,0,0,4.062-4.063V474.092a1.5,1.5,0,0,1,1.5-1.5h4.313v-5.424Z" />
      <path
        d="M2623.032,526.708c-11.308,0-22.729-2.081-32.435-6.837-16.018-7.851-17.954-16.321-16.759-22.044,2.467-11.81,20.455-20.457,35.708-21.126a2.5,2.5,0,1,1,.218,5c-14.5.635-29.289,8.81-31.031,17.152-1.51,7.227,6.815,12.979,14.065,16.531,23.258,11.4,58.36,6.038,73.038-6.181,3.216-2.677,6.764-6.881,5.412-11.708-1.572-5.611-6.589-10.608-14.511-14.451-18.669-9.059-51-10.37-69.186-2.809a2.5,2.5,0,0,1-1.92-4.617c19.633-8.163,53.195-6.823,73.289,2.928,9.246,4.486,15.174,10.572,17.142,17.6,1.619,5.779-.877,11.78-7.027,16.9C2659.075,521.332,2641.2,526.708,2623.032,526.708Z"
        fill={color.violetLoop}
      />
    </g>
  </IconSvg>
);
