import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconTransfer = (props: IconProps) => (
  <IconSvg
    height="128.69"
    viewBox="0 0 169.37 128.69"
    width="169.37"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-875.66 -461.482)">
      <path
        d="M953.881,581.069a2.493,2.493,0,0,1-.6-.074c-.059-.015-5.986-1.48-11.253-2.717a2.5,2.5,0,0,1-1.439-3.919l7.3-9.891a2.5,2.5,0,0,1,4.022,2.971l-5.056,6.848c4.041.968,7.577,1.842,7.623,1.854a2.5,2.5,0,0,1-.6,4.928Z"
        fill={color.violetLoop}
      />
      <path
        d="M960.132,557.887a2.5,2.5,0,0,1-.6-4.928c15.258-3.754,49.909-23.052,50.258-23.247a2.5,2.5,0,0,1,2.438,4.366c-1.446.807-35.591,19.821-51.5,23.736A2.533,2.533,0,0,1,960.132,557.887Z"
        fill={color.violetLoop}
      />
      <g>
        <g>
          <g>
            <path d="M1021.8,580.217H949.157A6.161,6.161,0,0,1,943,574.064V522.531H927.43a1.5,1.5,0,0,1-1.061-2.561l58.05-58.049a1.5,1.5,0,0,1,2.121,0l58.05,58.049a1.5,1.5,0,0,1-1.06,2.561h-15.574v51.533A6.16,6.16,0,0,1,1021.8,580.217Zm-90.752-60.686H944.5a1.5,1.5,0,0,1,1.5,1.5v53.033a3.158,3.158,0,0,0,3.154,3.153H1021.8a3.157,3.157,0,0,0,3.153-3.153V521.031a1.5,1.5,0,0,1,1.5-1.5h13.452L985.48,465.1Z" />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M993.26,530.987l-29.025-29.025L935.21,472.938l-29.025,29.024L877.16,530.987h17.074V584.02a4.667,4.667,0,0,0,4.653,4.653h72.646a4.667,4.667,0,0,0,4.653-4.653V530.987Z"
                fill={color.whiteLoop}
              />
              <path d="M971.533,590.172H898.887a6.159,6.159,0,0,1-6.153-6.152V532.487H877.16a1.5,1.5,0,0,1-1.06-2.561l58.049-58.048a1.5,1.5,0,0,1,2.122,0l58.049,58.048a1.5,1.5,0,0,1-1.06,2.561H977.686V584.02A6.159,6.159,0,0,1,971.533,590.172Zm-90.751-60.685h13.452a1.5,1.5,0,0,1,1.5,1.5V584.02a3.156,3.156,0,0,0,3.153,3.152h72.646a3.156,3.156,0,0,0,3.153-3.152V530.987a1.5,1.5,0,0,1,1.5-1.5h13.453L935.21,475.059Z" />
            </g>
          </g>
        </g>
        <path
          d="M948.829,576.117a2.5,2.5,0,0,1-.758-4.883c21.857-6.959,53.162-23.882,53.475-24.052a2.5,2.5,0,1,1,2.384,4.4c-1.3.7-32.03,17.317-54.342,24.421A2.478,2.478,0,0,1,948.829,576.117Z"
          fill={color.violetLoop}
        />
        <path
          d="M1010.312,541.92a2.5,2.5,0,0,1-2.3-3.469l3.019-7.182c-3.835-.14-6.919-.576-7.088-.6a2.5,2.5,0,0,1,.71-4.95,68.274,68.274,0,0,0,10.058.573,2.5,2.5,0,0,1,2.379,3.467l-4.469,10.629A2.5,2.5,0,0,1,1010.312,541.92Z"
          fill={color.violetLoop}
        />
      </g>
    </g>
  </IconSvg>
);
