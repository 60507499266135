import React, { useState } from 'react';
import { PropertyOptionsObject } from '../../enums/property-options';
import { PropertyOptionsModel } from '../../types/property-options';
import { AutoCompleteInput } from '../inputs';
import { StepWrapper } from '../wrappers/step-wrapper';

interface StepSuburbProps {
  austrilianState2Prop: string;
  austrilianStateProp: string;
  onNextButton: ({
    newAustrilianState,
    newAustrilianState2,
    newSuburb,
    newSuburb2
  }: {
    newAustrilianState2: string;
    newAustrilianState: string;
    newSuburb2: string;
    newSuburb: string;
  }) => void;
  onPreviousButton: () => void;
  propertyOption: PropertyOptionsModel;
  suburb2State: string;
  suburbState: string;
}

interface StepSuburbState {
  austrilianState2: string;
  austrilianState: string;
  suburb2: string;
  suburb: string;
}

const StepSuburb = (props: StepSuburbProps) => {
  const {
    austrilianState2Prop,
    austrilianStateProp,
    onNextButton,
    onPreviousButton,
    propertyOption,
    suburb2State,
    suburbState
  } = props;

  const { propertyTypeTitle1, propertyTypeTitle2 } = PropertyOptionsObject[propertyOption];
  const [touched, setTouched] = useState(false);
  const [state, setState] = useState<StepSuburbState>({
    austrilianState2: austrilianState2Prop,
    austrilianState: austrilianStateProp,
    suburb2: suburb2State,
    suburb: suburbState
  });

  const stateSetter = (updates: Partial<typeof state>) =>
    setState((previousState) => ({ ...previousState, ...updates }));

  const { austrilianState, austrilianState2, suburb, suburb2 } = state;

  const handleOnSuburbChange = (newAustrilianState: string, newSuburb: string) => {
    stateSetter({ austrilianState: newAustrilianState, suburb: newSuburb });
    onNextButton({
      newAustrilianState2: '',
      newAustrilianState,
      newSuburb,
      newSuburb2: ''
    });
  };

  const handleOnSuburb2Change = (newAustrilianState: string, newSuburb: string) => {
    stateSetter({ austrilianState2: newAustrilianState, suburb2: newSuburb });
    onNextButton({
      newAustrilianState2: newAustrilianState,
      newAustrilianState: '',
      newSuburb: '',
      newSuburb2: newSuburb
    });
  };
  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <StepWrapper
      children={
        <AutoCompleteInput
          onChange={handleOnSuburbChange}
          onBlurCapture={handleBlur}
          placeholder="E.g. MELBOURNE, VIC, 3000"
          value={{ formatedSuburb: suburb, state: austrilianState }}
          autofocus
          errorMessage={touched && !(suburb || austrilianState) ? '*Mandatory Field' : ''}
        />
      }
      children2={
        propertyTypeTitle2 && (
          <AutoCompleteInput
            onChange={handleOnSuburb2Change}
            onBlurCapture={handleBlur}
            placeholder="E.g. MELBOURNE, VIC, 3000"
            value={{ formatedSuburb: suburb2, state: austrilianState2 }}
            autofocus
            errorMessage={touched && !(austrilianState2 || suburb2) ? '*Mandatory Field' : ''}
          />
        )
      }
      nextButtonDisabled={propertyTypeTitle2 ? !(suburb && suburb2) : !suburb}
      onPreviousButton={onPreviousButton}
      onNextButton={() =>
        onNextButton({
          newAustrilianState2: austrilianState2,
          newAustrilianState: austrilianState,
          newSuburb: suburb,
          newSuburb2: suburb2
        })
      }
      subTitle="Tell us what suburb or postcode the property is in"
      title2={`THE PROPERTY ${propertyTypeTitle2} IS IN...`}
      title={`THE PROPERTY ${propertyTypeTitle1} IS IN...`}
    />
  );
};

export { StepSuburb };
