import styled from 'styled-components';

const Form = styled.form({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
  width: '100%'
});

Form.displayName = 'Form';

export { Form };
