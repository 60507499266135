import { useState } from 'react';
import styled from 'styled-components';
import { OptionalQuestionsModel } from '../../types/optional-questions';
import { CheckBoxInput } from '../inputs';
import { Flex } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const CheckboxInputContainer = styled(Flex)({
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '20px',
  width: '100%'
});

interface StepOptionalQuestionsProps {
  onNextButton: (newOptionalQuestions: OptionalQuestionsModel) => void;
  onPreviousButton: () => void;
  optionalQuestionsState: OptionalQuestionsModel;
}

const StepOptionalQuestions = (props: StepOptionalQuestionsProps) => {
  const { onPreviousButton, onNextButton, optionalQuestionsState } = props;

  const [optionalQuestions, setOptionalQuestions] =
    useState<OptionalQuestionsModel>(optionalQuestionsState);

  const checkBoxInputItems = Object.keys(optionalQuestions).map((ques) => {
    return {
      checked: optionalQuestions[ques],
      label: ques,
      onChange: (newInputValue: boolean) =>
        setOptionalQuestions((previousState) => ({ ...previousState, [ques]: newInputValue }))
    };
  });

  return (
    <StepWrapper
      onPreviousButton={onPreviousButton}
      onGetYourQuoteButton={() => onNextButton(optionalQuestions)}
      onNextButton={() => onNextButton(optionalQuestions)}
      subTitle="Answering these optional questions will speed up the process, but you don’t need to tell us now."
      title="DO WE NEED TO KNOW ANYTHING ELSE?"
    >
      <CheckboxInputContainer>
        {checkBoxInputItems.map((itemProps) => (
          <CheckBoxInput key={itemProps.label} {...itemProps} />
        ))}
      </CheckboxInputContainer>
    </StepWrapper>
  );
};

export { StepOptionalQuestions };
