import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontFamily } from '../../constants/font-family';

const Text = styled.div({
  color: color.blackLoop,
  fontFamily: fontFamily.montserrat
});

Text.displayName = 'Text';

const ErrorText = styled.div({
  color: 'red',
  fontFamily: fontFamily.montserrat,
  paddingTop: '4px',
  '&:first-letter': {
    textTransform: 'uppercase'
  }
});

ErrorText.displayName = 'ErrorText';

export { Text, ErrorText };
