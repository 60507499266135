import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconExistingHouse = (props: IconProps) => (
  <IconSvg
    height="132.612"
    viewBox="0 0 133.021 132.612"
    width="133.021"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-2232.049 -456.403)">
      <g>
        <g>
          <g>
            <path d="M2339.238,589.015h-81.357a6.719,6.719,0,0,1-6.711-6.711V524.413h-17.621a1.5,1.5,0,0,1-1.06-2.561l65.01-65.01a1.5,1.5,0,0,1,2.121,0l65.01,65.01a1.5,1.5,0,0,1-1.06,2.561h-17.621V582.3A6.719,6.719,0,0,1,2339.238,589.015Zm-102.067-67.6h15.5a1.5,1.5,0,0,1,1.5,1.5V582.3a3.716,3.716,0,0,0,3.711,3.711h81.357a3.715,3.715,0,0,0,3.711-3.711V522.913a1.5,1.5,0,0,1,1.5-1.5h15.5l-61.389-61.389Z" />
          </g>
        </g>
        <path
          d="M2287.637,569.294H2287a2.507,2.507,0,0,1-2.461-2.912c-.035.006-.072.011-.108.015a2.515,2.515,0,0,1-2.751-2.221c-.011-.1-1.1-10.392-1.765-20.747-1.439-22.533.422-23.416,2.221-24.268,3.162-1.5,27.228-4.582,34.145-1.413a4.562,4.562,0,0,1,2.735,2.894c1.489,5.4,4.868,36.951-.768,45.269a5.388,5.388,0,0,1-3.906,2.56C2307.325,569.251,2291.122,569.294,2287.637,569.294Zm-2.8-45.824c-1.112,3.981.029,23.506,1.809,40.174a2.524,2.524,0,0,1-.019.676,1.669,1.669,0,0,1,.378-.027c.19,0,19.346.034,26.779-.791-.02,0,2.862-1.916,2.549-18.909-.173-9.351-1.3-19.123-2.056-22.282C2310.745,520.082,2289.567,522.01,2284.839,523.47Z"
          fill={color.violetLoop}
        />
        <path
          d="M2284.691,546.415a2.5,2.5,0,0,1-.134-5c.891-.048,21.937-1.185,33.3-.836a2.5,2.5,0,1,1-.152,5c-11.155-.346-32.662.818-32.878.83C2284.783,546.414,2284.737,546.415,2284.691,546.415Z"
          fill={color.violetLoop}
        />
        <path
          d="M2301.24,569.293h-.078a2.5,2.5,0,0,1-2.422-2.576c.01-.333.984-33.41-1.492-46.24a2.5,2.5,0,1,1,4.909-.947c2.581,13.373,1.622,45.959,1.58,47.34A2.5,2.5,0,0,1,2301.24,569.293Z"
          fill={color.violetLoop}
        />
      </g>
    </g>
  </IconSvg>
);
