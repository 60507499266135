import styled from 'styled-components';
import { boxShadow } from '../../constants/box-shadow';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';

const Button = styled.button({
  background: color.violetLoop,
  border: 'none',
  borderRadius: '2px',
  color: color.whiteLoop,
  fontSize: fontSize.s,
  height: '48px',
  minWidth: '188px',
  cursor: 'pointer',

  ':hover': {
    boxShadow: boxShadow.regular,

    ':disabled': {
      background: color.greyLoop,
      boxShadow: 'none',
      color: color.whiteLoop,
      cursor: 'not-allowed'
    }
  },

  ':disabled': {
    background: color.greyLoop,
    color: color.whiteLoop,
    cursor: 'not-allowed'
  }
});
Button.displayName = 'Button';

const ButtonPrevious = styled(Button).attrs({ type: 'button' })({
  background: color.whiteLoop,
  border: `3px solid ${color.blackLoop}`,
  color: color.blackLoop,
  paddingRight: '13px'
});

ButtonPrevious.displayName = 'ButtonPrevious';

ButtonPrevious.defaultProps = {
  children: '< Previous'
};

const ButtonNext = styled(Button)({
  paddingLeft: '13px'
});

ButtonNext.defaultProps = {
  children: 'Next >'
};

export { Button, ButtonNext, ButtonPrevious };
