import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';

const Input = styled.input.attrs({
  type: 'text'
})({
  background: color.whiteLoop,
  border: 'none',
  color: color.blackLoop,
  fontSize: fontSize.s,
  outline: 'none',
  width: '100%'
});

Input.displayName = 'Input';

export { Input };
