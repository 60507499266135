import Downshift from 'downshift';
import styled from 'styled-components';
import { IconArrowDown } from '../../assets/icons/inputs';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { ErrorText } from '../primitives';

const Input = styled.input({
  width: '100%',
  outline: 'none',
  border: `2px solid ${color.blackLoop}`,
  borderRadius: '4px',
  height: '60px',
  padding: '0 28px',
  fontSize: fontSize.s,
  cursor: 'pointer',
  '&:focus': {
    borderColor: color.violetLoop
  },
  '&:focus + button > svg': {
    color: color.violetLoop
  }
});

const InputBox = styled.div({
  width: '100%',
  maxWidth: '624px'
});

const TransparentButton = styled.button({
  border: 'none',
  background: 'transparent'
});

const FlexBox = styled.div({
  width: '100%',
  cursor: 'pointer',
  maxWidth: '624px',
  position: 'relative',
  border: 'none',
  outline: 'none',
  '&:focus > input': {
    borderColor: color.violetLoop
  }
});

const DropdownMenu = styled.ul({
  background: color.whiteLoop,
  border: `2px solid ${color.blackLoop}`,
  borderTop: 'none',
  maxHeight: '240px',
  overflow: 'auto',
  position: 'absolute',
  width: '100%',
  maxWidth: '624px'
});

const DropdownItem = styled.li({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: fontSize.s,
  height: '48px',
  padding: '0 28px',

  ':hover': {
    background: color.greyLoop
  }
});

const IconArrow = styled(IconArrowDown)({
  position: 'absolute',
  right: '24px',
  top: '22px'
});

interface SelectInputProps {
  dropdownOptions: string[];
  onChange: (newValue: string) => void;
  onUserAction?: () => void;
  placeholder: string;
  value: string | null;
  errorMessage?: string;
  name?: string;
}

const SelectInput = (props: SelectInputProps) => {
  const {
    dropdownOptions = [],
    onChange,
    placeholder,
    value,
    errorMessage,
    onUserAction,
    ...restProps
  } = props;

  const handleOnItemSelected = (selectedItem: string | null) => {
    onChange(selectedItem || '');
  };

  return (
    <Downshift
      selectedItem={value}
      onChange={(selection) => handleOnItemSelected(selection)}
      onUserAction={() => onUserAction && onUserAction()}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        getRootProps
      }) => {
        const { type, ...buttonProps } = getToggleButtonProps();
        console.log({ inp: getInputProps() });
        return (
          <InputBox>
            <FlexBox {...getRootProps({}, { suppressRefError: true })}>
              <Input
                placeholder={placeholder}
                {...getInputProps()}
                readOnly
                {...buttonProps}
                {...restProps}
              />
              <TransparentButton {...getToggleButtonProps()}>
                <IconArrow arrowDirectionUp={isOpen} />
              </TransparentButton>
            </FlexBox>
            {errorMessage && !isOpen && <ErrorText>{errorMessage}</ErrorText>}
            {isOpen && dropdownOptions.length !== 0 && (
              <DropdownMenu {...getMenuProps()}>
                {dropdownOptions.map((option, index) => {
                  console.log({ highlightedIndex, index });
                  return (
                    <DropdownItem
                      key={`${option}${index}`}
                      style={{
                        background: highlightedIndex === index ? color.greyLoop : color.whiteLoop
                      }}
                      {...getItemProps({
                        index,
                        item: option,
                        selected: selectedItem === value,
                        key: option
                      })}
                    >
                      {option}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            )}
          </InputBox>
        );
      }}
    </Downshift>
  );
};

export default SelectInput;
