enum PropertyTypeEnum {
  AnAppartmentOrUnitOrTownHouse = 'AnAppartmentOrUnitOrTownHouse',
  AnExistingHouse = 'AnExistingHouse',
  Land = 'Land',
  OffThePlan = 'OffThePlan'
}

const PropertyTypeLabelObject = {
  AnAppartmentOrUnitOrTownHouse: 'An Appartment Or Unit Or TownHouse',
  AnExistingHouse: 'An Existing House',
  Land: 'Land',
  OffThePlan: 'Off The Plan'
};

export { PropertyTypeEnum, PropertyTypeLabelObject };
