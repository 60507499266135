import { reCaptchaScriptURL } from '../services/recaptcha';

export const loadScript = (src: string, elementId: string) => {
  let scriptEl = document.getElementById(elementId) as HTMLScriptElement;
  if (scriptEl) {
    return scriptEl;
  }
  let tag = document.createElement('script');
  tag.async = false;
  tag.id = elementId;
  tag.src = src;
  let body = document.getElementsByTagName('body')[0];
  body.appendChild(tag);
  return tag;
};

export function removeScriptTag(scriptEl: HTMLScriptElement) {
  document.body?.removeChild(scriptEl);
}

export const loadRecaptchaScript = () => {
  return loadScript(reCaptchaScriptURL, 'recaptcha');
};

export function removeRecaptchaScript(scriptEl: HTMLScriptElement) {
  document.body.removeChild(scriptEl);
  let reCaptchaWidgets = document.getElementsByClassName('grecaptcha-badge');
  Array.from({ length: reCaptchaWidgets.length }).map((_, i) =>
    reCaptchaWidgets.item(i)?.parentElement?.remove()
  );
}
