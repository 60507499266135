const legalPagesLink = [
  {
    label: 'Privacy Policy',
    href: 'https://listingloop.com.au/privacy/'
  },
  {
    label: 'Terms and Conditions',
    href: 'https://listingloop.com.au/terms-conditions/'
  },
  {
    label: 'Privacy Collection',
    href: 'https://listingloop.com.au/privacy-collection-statements/'
  }
];

const generalPagesLink = [
  {
    label: 'Sale Contract',
    href: 'https://conveyancingloop.com.au/contract-of-sale/'
  },
  {
    label: 'Sale Conveyancing',
    href: 'https://conveyancingloop.com.au/sale-conveyancing/'
  },
  {
    label: 'Purchase Conveyancing',
    href: 'https://conveyancingloop.com.au/purchase-conveyancing/'
  },
  {
    label: 'About us',
    href: 'https://conveyancingloop.com.au/about-us/'
  }
];

export { legalPagesLink, generalPagesLink };
