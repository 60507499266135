import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { Box } from './box';
import { Text } from './text';

const DropdownMenu = styled(Box)({
  background: color.whiteLoop,
  border: `2px solid ${color.blackLoop}`,
  borderTop: 'none',
  maxHeight: '240px',
  overflow: 'auto',
  position: 'absolute',
  width: '100%'
});

const DropdownItem = styled(Text)({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: fontSize.s,
  height: '48px',
  padding: '0 28px',

  ':hover': {
    background: color.greyLoop
  }
});

export { DropdownItem, DropdownMenu };
