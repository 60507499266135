import { gql } from '@apollo/client';

export const suggestionsQuery = gql`
query SuburbSuggestions($input: String = "", $limit: Int = 20) {
  allSpecializationAreas(
    LIMIT: $limit
    OR: [{ postcode_contains: $input }, { state_contains: $input }, { suburb_contains: $input }]
  ) {
    SpecializationAreas {
      id: _id
      postcode
      state
      suburb
      suburbDisplayName
      getRegion {
        regionDisplayName
      }
    }
  }

  allRegions(LIMIT: $limit, OR: [{ state_contains: $input }, { region_contains: $input }]) {
    Regions {
       id: _id
    region
    regionDisplayName
    state
    }
  }
}
`;
