import styled from 'styled-components';
import { CheckboxToggle } from '../checkbox-toggle';
import { Text, TextContainer } from '../primitives';

const CheckBoxInputContainer = styled(TextContainer)({
  columnGap: '20px',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  paddingLeft: '16px',
  paddingRight: '16px'
});

interface CheckBoxInputProps {
  checked: boolean;
  label: string | JSX.Element;
  onChange: (newValue: boolean) => void;
}

const CheckBoxInput = (props: CheckBoxInputProps) => {
  const { checked, label, onChange } = props;

  const handleOnInputChange = () => onChange(!checked);

  return (
    <CheckBoxInputContainer onClick={handleOnInputChange}>
      <CheckboxToggle checked={checked} />
      <Text>{label}</Text>
    </CheckBoxInputContainer>
  );
};

export { CheckBoxInput };
