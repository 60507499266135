import styled from 'styled-components';
import { Box } from './box';
import { fontFamily } from '../../constants/font-family';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';

const highlightCircleUrl = 'https://images.listingloop.com.au/images/highlight-circle.svg';

const StyledRichtext = styled(Box)`
  font-size:18px;
  line-height:1.5;
  font-weight:400;
  &:p{
    font-size:inherit;
    margin-bottom:24px;
  };
  &:blockquote{
    font-family:${fontFamily.montserrat},
    font-size:3rem;
    margin-bottom:24px;
    position:relative;
    padding-top:50px;
    padding-bottom:50px;
    &:after{
      content: "''";
      background-image: url("${highlightCircleUrl}");
      background-position: 'center';
      background-size: 100%;
      height: 100%;
      width: 100%;
      position: absolute;
      top: -16px;
      left: 0;
      background-repeat: no-repeat;
      transform: scaleY(1.3);
      @media (max-width: ${deviceTypeBreakpoint.tab}) {
        top:-6px;
      }
      @media (max-width:400px){
        transform: scaleY(2.3);
      }
    };
    @media (max-width:${deviceTypeBreakpoint.desktop}){
      &:blockquote: {
        font-size: '2.5rem';
      }
    }
    @media(max-width:${deviceTypeBreakpoint.tab}){
      font-size: '16px',
    '& blockquote': {
      font-size: '1.25rem'
    }
    }
  }
`;

const Richtext = (props: { text: string }) => {
  const { text, ...restProps } = props;
  return <StyledRichtext dangerouslySetInnerHTML={{ __html: text }} {...restProps} />;
};
export default Richtext;
