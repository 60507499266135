import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { PropertyOptionsObject } from '../../enums/property-options';
import { isValidPhoneNumber } from '../../methods/valid-phone-number';
import { AboutYouFormModel } from '../../types/about-you-form';
import { PropertyOptionsModel } from '../../types/property-options';
import { TextInput } from '../inputs';
import SelectInput from '../inputs/select-input';
import { Button, ButtonNext, ButtonPrevious, Flex, Form } from '../primitives';
import { ButtonContainer } from '../primitives/button-container';
import { StepWrapper } from '../wrappers/step-wrapper';

const ContactFormContainer = styled(Flex)({
  alignItems: 'center',
  flexDirection: 'column',
  justifyItems: 'center',
  marginBottom: '48px',
  rowGap: '20px',
  width: '100%'
});

const SubmitButton = styled(Button).attrs({
  type: 'submit'
})`
  margin-top: 52px;
`;

SubmitButton.defaultProps = {
  children: 'Submit'
};

type stringStates = 'sellingStatus' | 'contactNumber' | 'email' | 'name';

const validationSchema = Yup.object({
  name: Yup.string().required('*Mandatory field'),
  contactNumber: Yup.string().test('is-number', '*Enter valid phone number', (phoneNumber) =>
    isValidPhoneNumber(phoneNumber as string)
  ),
  email: Yup.string().email().required('*Mandatory field'),
  sellingStatus: Yup.string().required('*Mandatory field')
});

interface StepAboutYouProps {
  aboutUsFormIntialValues: AboutYouFormModel;
  onNextButton: (newAboutFormValues: AboutYouFormModel) => void;
  onPreviousButton: () => void;
  propertyOption: PropertyOptionsModel;
}

const StepAboutYou = (props: StepAboutYouProps) => {
  const { aboutUsFormIntialValues, onNextButton, onPreviousButton, propertyOption } = props;

  const initialValues: AboutYouFormModel = { ...aboutUsFormIntialValues };

  const {
    dirty,
    touched,
    handleSubmit,
    isValid,
    setFieldValue,
    values,
    errors,
    handleChange,
    handleBlur,
    setTouched,
    validateField
  } = useFormik({
    initialValues,
    onSubmit: (formValues) => onNextButton(formValues),
    validationSchema
  });

  let dropdownItems = PropertyOptionsObject[propertyOption].statusOptions;
  const placeholderItem: string = dropdownItems[0];

  dropdownItems = dropdownItems.slice(1);

  const { contactNumber, email, name, sellingStatus } = values;

  const handleOnInputChange = (stateName: stringStates, newValue: string) => {
    setFieldValue(stateName, newValue);
    validateField(stateName);
  };
  console.log({ touched, errors, values });
  return (
    <StepWrapper subTitle="This will help us get in touch with you" title="TELL US ABOUT YOU">
      <Form onSubmit={handleSubmit}>
        <ContactFormContainer>
          <TextInput
            onChangeRaw={handleChange}
            onBlur={handleBlur}
            placeholder="Your Name*"
            name="name"
            value={name}
            autofocus
            errorMessage={touched.name && errors.name ? errors.name : ''}
          />
          <TextInput
            onChangeRaw={handleChange}
            onBlur={handleBlur}
            placeholder="Phone Number*"
            name="contactNumber"
            value={contactNumber}
            errorMessage={touched.contactNumber && errors.contactNumber ? errors.contactNumber : ''}
          />
          <TextInput
            onChangeRaw={handleChange}
            onBlur={handleBlur}
            placeholder="Email address*"
            name="email"
            value={email}
            errorMessage={touched.email && errors.email ? errors.email : ''}
          />
          <SelectInput
            dropdownOptions={dropdownItems}
            onChange={(newValue) => handleOnInputChange('sellingStatus', newValue)}
            onUserAction={() => setTouched({ ...touched, sellingStatus: true })}
            placeholder={placeholderItem}
            value={sellingStatus}
            name="sellingStatus"
            errorMessage={
              touched.sellingStatus && !sellingStatus && errors.sellingStatus
                ? errors.sellingStatus
                : ''
            }
          />
        </ContactFormContainer>
        <ButtonContainer>
          <ButtonPrevious onClick={onPreviousButton} />
          <ButtonNext disabled={!dirty || !isValid} type="submit" />
        </ButtonContainer>
      </Form>
    </StepWrapper>
  );
};

export { StepAboutYou };
