import { useLazyQuery } from '@apollo/client';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { convertToGqlSpecializationAreas } from '../../converter';
import { formatSuburbAddress } from '../../formatter';
import {
  SuburbSuggestions,
  SuburbSuggestionsVariables
} from '../../interfaces/generated/SuburbSuggestions';
import { suggestionsQuery } from '../../queries/suburb-suggestions';
import { Box, DropdownItem, DropdownMenu, ErrorText } from '../primitives';

const Input = styled.input({
  width: '100%',
  outline: 'none',
  border: `2px solid ${color.blackLoop}`,
  borderRadius: '4px',
  height: '60px',
  padding: '0 28px',
  fontSize: fontSize.s,
  '&:focus': {
    borderColor: color.violetLoop
  }
});

const AutoCompleteInputContainer = styled(Box)`
  margin: 0 auto;
  max-width: 624px;
  position: relative;
`;

const AutoCompleteInputOuterContainer = styled(Box)`
  width: 100%;
`;

interface AutoCompleteInputProps {
  onChange: (newState: string, newSuburb: string) => void;
  onUserAction?: () => void;
  placeholder: string;
  value: { formatedSuburb: string; state: string };
  errorMessage?: string;
  autofocus?: boolean;
  onBlurCapture?: () => void;
}

interface AutoCompleteInputState {
  suburbSuggestions: {
    state: string;
    formatedSuburb: string;
  }[];
}

const AutoCompleteInput = (props: AutoCompleteInputProps) => {
  const { onChange, value, errorMessage, onUserAction, onBlurCapture, ...restProps } = props;

  const [state, setState] = useState<AutoCompleteInputState>({
    suburbSuggestions: []
  });

  const stateSetter = (updates: Partial<AutoCompleteInputState>) =>
    setState((previousState) => ({ ...previousState, ...updates }));

  const { suburbSuggestions } = state;

  const setSuburbSuggestions = (newValue: typeof suburbSuggestions) =>
    stateSetter({ suburbSuggestions: newValue });

  const [fetchSuburbSuggestions] = useLazyQuery<SuburbSuggestions, SuburbSuggestionsVariables>(
    suggestionsQuery
  );

  const loadSuggestions = useCallback(
    debounce(async (newValue: string) => {
      if (newValue.length === 0) {
        setSuburbSuggestions([]);
      } else {
        const response = await fetchSuburbSuggestions({
          variables: {
            input: newValue
          }
        });

        const convertedSuggestions = response?.data
          ? convertToGqlSpecializationAreas({ suggestionsFromQuery: response.data })
          : [];

        const newSuggestions2: typeof suburbSuggestions = convertedSuggestions.map((item) => ({
          state: item.state,
          formatedSuburb: formatSuburbAddress(item)
        }));

        setSuburbSuggestions(newSuggestions2);
      }
    }, 250),
    []
  );

  const handleOnInputValueChange = (newValue: string) => {
    loadSuggestions(newValue);
  };

  return (
    <AutoCompleteInputOuterContainer>
      <Downshift
        selectedItem={value}
        itemToString={(item) => item?.formatedSuburb || ''}
        onChange={(selection) => console.log({ selection })}
        onUserAction={onUserAction}
        onInputValueChange={handleOnInputValueChange}
        onSelect={(selectedItem) => {
          if (!selectedItem) onChange('', '');
          else onChange(selectedItem.state.toUpperCase(), selectedItem.formatedSuburb);
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          selectedItem,
          highlightedIndex,
          getRootProps
        }) => {
          const inputProps = getInputProps();
          console.log({ inputProps });
          return (
            <AutoCompleteInputContainer {...getRootProps({}, { suppressRefError: true })}>
              <Input {...getInputProps()} {...restProps} onBlurCapture={onBlurCapture} />
              {isOpen && suburbSuggestions.length > 0 && (
                <DropdownMenu {...getMenuProps()}>
                  {suburbSuggestions.map((item, index) => (
                    <DropdownItem
                      key={index}
                      style={{
                        background: highlightedIndex === index ? color.greyLoop : color.whiteLoop
                      }}
                      // onClick={() => handleOnItemSelected(state, text)}
                      {...getItemProps({
                        key: index,
                        index,
                        item: item,
                        selected: selectedItem?.state === item.state
                      })}
                    >
                      {item.formatedSuburb}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              )}
              {errorMessage && !suburbSuggestions.length && <ErrorText>{errorMessage}</ErrorText>}
            </AutoCompleteInputContainer>
          );
        }}
      </Downshift>
    </AutoCompleteInputOuterContainer>
  );
};

export { AutoCompleteInput };
