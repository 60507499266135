const fontSize = {
  xxs: '14px',
  xs: '16px',
  s: '18px',
  m: '21px',
  xm: '30px',
  xxm: '34px',
  l: '35px',
  xl: '67px',
  xxl: '92px'
};

export { fontSize };
