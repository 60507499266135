import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconBuyAndSell = (props: IconProps) => (
  <IconSvg
    height="129.494"
    viewBox="0 0 183.28 129.494"
    width="183.28"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-1194.865 -461.338)">
      <path
        d="M1211.21,548.881a2.5,2.5,0,0,1-2.391-1.774l-2.369-7.8c-3.787,2.841-7.551,5.629-7.6,5.663a2.5,2.5,0,0,1-2.975-4.017c.059-.044,5.969-4.422,10.418-7.787a2.5,2.5,0,0,1,3.9,1.266l3.408,11.227a2.5,2.5,0,0,1-2.393,3.227Z"
        fill={color.violetLoop}
      />
      <path
        d="M1364.716,521.253a2.5,2.5,0,0,1-2.434-1.931l-2.665-11.426a2.5,2.5,0,0,1,4.869-1.137l1.853,7.944c3.965-2.587,7.9-5.121,7.952-5.154a2.5,2.5,0,0,1,2.706,4.205c-.062.041-6.246,4.02-10.906,7.087A2.494,2.494,0,0,1,1364.716,521.253Z"
        fill={color.violetLoop}
      />
      <path
        d="M1365.945,517.557a2.5,2.5,0,0,1-2.1-3.847c2.67-4.172,5-9.817,3.519-13.145-.761-1.713-2.671-2.836-5.677-3.338-4.663-.778-14.366-2.4-39.937,1.443a2.5,2.5,0,0,1-.743-4.946c26.355-3.957,36.586-2.248,41.5-1.429,4.768.8,7.938,2.9,9.423,6.24,2.8,6.294-1.78,14.593-3.877,17.87A2.5,2.5,0,0,1,1365.945,517.557Z"
        fill={color.violetLoop}
      />
      <path
        d="M1217,525.306a2.5,2.5,0,0,1-.873-4.844c5.534-2.06,11.135-4.045,16.647-5.9a2.5,2.5,0,1,1,1.595,4.738c-5.462,1.838-11.012,3.806-16.5,5.848A2.494,2.494,0,0,1,1217,525.306Z"
        fill={color.violetLoop}
      />
      <g>
        <g>
          <g>
            <path
              d="M1350.078,526.283l-31.723-31.722-31.724-31.723-31.723,31.723-31.723,31.722h18.661v57.963a5.1,5.1,0,0,0,5.085,5.086h79.4a5.1,5.1,0,0,0,5.085-5.086V526.283Z"
              fill={color.whiteLoop}
            />
            <path d="M1326.332,590.832h-79.4a6.592,6.592,0,0,1-6.585-6.585V527.784h-17.161a1.5,1.5,0,0,1-1.061-2.561l63.447-63.446a1.5,1.5,0,0,1,2.121,0l63.447,63.446a1.5,1.5,0,0,1-1.061,2.561h-17.161v56.463A6.592,6.592,0,0,1,1326.332,590.832Zm-99.526-66.048h15.04a1.5,1.5,0,0,1,1.5,1.5v57.963a3.589,3.589,0,0,0,3.585,3.585h79.4a3.589,3.589,0,0,0,3.585-3.585V526.284a1.5,1.5,0,0,1,1.5-1.5h15.039l-59.825-59.825Z" />
          </g>
        </g>
      </g>
      <path
        d="M1243.107,567.136c-20.3,0-32.586-3.428-38.016-10.442-3.582-4.628-4.059-10.618-1.417-17.8a2.5,2.5,0,1,1,4.693,1.725c-2.037,5.54-1.815,9.8.678,13.019,9.373,12.106,48.294,8.238,61.085,6.967,45.846-4.557,84.456-22.456,87.11-30.039a2.5,2.5,0,0,1,4.719,1.652c-3.963,11.326-46.888,28.945-91.334,33.362C1260.187,566.613,1251.035,567.136,1243.107,567.136Z"
        fill={color.violetLoop}
      />
    </g>
  </IconSvg>
);
