import { useState } from 'react';
import styled from 'styled-components';
import {
  IconAppartment,
  IconExistingHouse,
  IconLand,
  IconOffThePlan
} from '../../assets/icons/property-type';
import { PropertyOptionsObject } from '../../enums/property-options';
import { PropertyTypeEnum } from '../../enums/property-type';
import { IconWithLabelModel } from '../../types/icon-with-label';
import { PropertyOptionsModel } from '../../types/property-options';
import { PropertyTypeModel } from '../../types/property-type';
import { IconWithLabel } from '../icon-with-label';
import { Grid } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const IconWithLabelContainer = styled(Grid)`
  align-items: center;
  column-gap: 32px;
  grid-template-columns: repeat(4, minmax(0, 296px));
  row-gap: 32px;
  cursor: pointer;

  @media (max-width: 1328px) {
    grid-template-columns: repeat(2, minmax(0, 296px));
    flex-direction: column;
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, minmax(0, 296px));
    flex-direction: column;
  }
`;

const { AnAppartmentOrUnitOrTownHouse, AnExistingHouse, Land, OffThePlan } = PropertyTypeEnum;

interface StepPropertyTypeProps {
  onNextButton: (
    newPropertyType: PropertyTypeModel | null,
    newPropertyType2: PropertyTypeModel | null
  ) => void;
  onPreviousButton: () => void;
  propertyOption: PropertyOptionsModel;
  propertyType2State: PropertyTypeModel | null;
  propertyTypeState: PropertyTypeModel | null;
}

interface StepPropertyTypeState {
  propertyType2: PropertyTypeModel | null;
  propertyType: PropertyTypeModel | null;
}

const StepPropertyType = (props: StepPropertyTypeProps) => {
  const { onNextButton, onPreviousButton, propertyOption, propertyType2State, propertyTypeState } =
    props;
  const { propertyTypeTitle1, propertyTypeTitle2 } = PropertyOptionsObject[propertyOption];

  const [state, setState] = useState<StepPropertyTypeState>({
    propertyType: propertyTypeState,
    propertyType2: propertyType2State
  });

  const stateSetter = (updates: Partial<typeof state>) =>
    setState((previousState) => ({ ...previousState, ...updates }));

  const { propertyType, propertyType2 } = state;

  const setPropertyType = (newValue: PropertyTypeModel | null) =>
    stateSetter({ propertyType: newValue });

  const setPropertyType2 = (newValue: PropertyTypeModel | null) =>
    stateSetter({ propertyType2: newValue });

  const handleNextButton = () => {
    onNextButton(propertyType, propertyType2);
  };

  const IconWithLabelElements: IconWithLabelModel[] = [
    {
      Icon: IconExistingHouse,
      label: 'An Existing House',
      keyValue: AnExistingHouse
    },
    {
      Icon: () => IconAppartment({ marginLeft: '30px' }),
      label: 'An Appartment/Unit/\nTownHouse',
      keyValue: AnAppartmentOrUnitOrTownHouse
    },
    {
      Icon: IconOffThePlan,
      label: 'Off The Plan',
      keyValue: OffThePlan
    },
    {
      Icon: IconLand,
      label: Land,
      keyValue: Land
    }
  ];

  return (
    <StepWrapper
      children={
        <IconWithLabelContainer>
          {IconWithLabelElements.map((elementProps) => (
            <IconWithLabel
              key={elementProps.keyValue}
              {...elementProps}
              onClick={() => {
                setPropertyType(elementProps.keyValue as PropertyTypeModel);

                if (!propertyTypeTitle2) {
                  onNextButton(elementProps.keyValue as PropertyTypeModel, null);
                }
              }}
              selected={elementProps.keyValue === propertyType}
            />
          ))}
        </IconWithLabelContainer>
      }
      children2={
        propertyTypeTitle2 && (
          <IconWithLabelContainer>
            {IconWithLabelElements.map((elementProps) => (
              <IconWithLabel
                key={elementProps.keyValue}
                {...elementProps}
                onClick={() => setPropertyType2(elementProps.keyValue as PropertyTypeModel)}
                selected={elementProps.keyValue === propertyType2}
              />
            ))}
          </IconWithLabelContainer>
        )
      }
      nextButtonDisabled={propertyTypeTitle2 ? !(propertyType && propertyType2) : !propertyType}
      onNextButton={handleNextButton}
      onPreviousButton={onPreviousButton}
      subTitle="What kind of property is it?"
      title2={`THE PROPERTY ${propertyTypeTitle2} IS...`}
      title={`THE PROPERTY ${propertyTypeTitle1} IS...`}
    />
  );
};

export { StepPropertyType };
