import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';

const app = initializeApp({
  apiKey: 'AIzaSyAvmG0J9-kw2hb_aE0Izj5sU4eGskBysEE',
  authDomain: 'listing-loop-prod.firebaseapp.com',
  databaseURL: 'https://listing-loop-prod.firebaseio.com',
  messagingSenderId: '59724813024',
  projectId: 'listing-loop-prod',
  storageBucket: 'listing-loop-prod.appspot.com'
});

const firebaseFunctions = getFunctions(app);
firebaseFunctions.region = 'australia-southeast1';

export { firebaseFunctions };
