import styled from 'styled-components';
import { Box } from './box';

const Grid = styled(Box)({
  display: 'grid'
});

Grid.displayName = 'Grid';

export { Grid };
