import { ChangeEvent } from 'react';
import { Box, ErrorText, Input, TextContainer } from '../primitives';

interface TextInputProps {
  onChange?: (newValue: string) => void;
  onChangeRaw?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  placeholder: string;
  value: string;
  autofocus?: boolean;
  errorMessage?: string;
  name?: string;
}

const TextInput = (props: TextInputProps) => {
  const { onChange, placeholder, value, autofocus, errorMessage, onChangeRaw, ...restProps } =
    props;

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    if (onChangeRaw) onChangeRaw(event);
    if (onChange) onChange(newValue);
  };

  return (
    <Box style={{ width: '100%', maxWidth: '624px' }}>
      <TextContainer style={{ padding: 0 }}>
        <Input
          style={{ padding: '14px 1px 14px 24px' }}
          onChange={onInputChange}
          placeholder={placeholder}
          value={value}
          autoFocus={autofocus}
          {...restProps}
        />
      </TextContainer>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </Box>
  );
};

export { TextInput };
