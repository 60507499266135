declare global {
  interface Window {
    grecaptcha: any;
  }
}

// IMPORTANT!!! REPLACE WITH ENV VARIABLES
const RECAPTCHA_SITE_KEY = '6LfolLkdAAAAAJRvtvfbZGP5-R5fi_tGsx4QhDrf';
export const reCaptchaScriptURL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

export const submitCaptchaWithData = (
  executor: (token: string) => Promise<{ success: boolean }>,
  callbacks?: { onSuccess?: () => void; onError?: (params: { error: string }) => void }
) => {
  window.grecaptcha.ready(async function () {
    window.grecaptcha
      .execute(RECAPTCHA_SITE_KEY, { action: 'submit' })
      .then(async function (token: string) {
        let response = await executor(token);
        if (!callbacks) return;
        console.log({ capResponse: response });
        const { onError, onSuccess } = callbacks;
        if (response?.success) {
          onSuccess && onSuccess();
        } else {
          onError && onError({ error: 'Captcha Verification Failed' });
          console.log({ response });
        }
      });
  });
};
