import React from 'react';
import styled from 'styled-components';

const Anchor = styled('a')`
  text-decoration: none;
  color: inherit;
`;

const ExternalLink = (props: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { children, ...restProps } = props;
  return <Anchor {...restProps}>{children}</Anchor>;
};

export default ExternalLink;
