enum TypesOfStepEnum {
  aboutYou = 'aboutYou',
  optionalQuestions = 'optionalQuestions',
  propertyOptions = 'propertyOptions',
  propertyType = 'propertyType',
  suburb = 'suburb'
}

interface stepperItem {
  label: string;
  progress: number;
}

const stepperItems: Record<TypesOfStepEnum, stepperItem> = {
  propertyOptions: {
    label: 'PROPERTY OPTIONS',
    progress: Math.floor(100 / 6)
  },
  propertyType: {
    label: 'PROPERTY TYPE',
    progress: Math.floor(200 / 6)
  },

  suburb: {
    label: 'SUBURB',
    progress: Math.floor(300 / 6)
  },
  aboutYou: {
    label: 'ABOUT YOU',
    progress: Math.floor(400 / 6)
  },
  optionalQuestions: {
    label: 'OPTIONAL QUESTIONS',
    progress: Math.floor(500 / 6)
  }
};

const orderOfSteps = Object.keys(stepperItems) as TypesOfStepEnum[];

export { TypesOfStepEnum, stepperItems, orderOfSteps };
