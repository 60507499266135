import styled from 'styled-components';
import { IconTick } from '../assets/icons/inputs';
import { color } from '../constants/color';
import { Flex } from './primitives';

const IconTickContainer = styled(Flex)({
  alignItems: 'center',
  border: `2px solid ${color.blackLoop}`,
  borderRadius: '2px',
  height: '100%',
  justifyContent: 'center',
  maxHeight: '24px',
  maxWidth: '24px',
  width: '100%'
});

interface CheckboxToggleProps {
  checked: boolean;
}

export const CheckboxToggle = (props: CheckboxToggleProps) => {
  const { checked } = props;

  return <IconTickContainer>{checked && <IconTick />}</IconTickContainer>;
};
