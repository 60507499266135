import { httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer, Header, ProgressTracker } from './components';
import { Box, Flex } from './components/primitives';
import {
  StepAboutYou,
  StepOptionalQuestions,
  StepPropertyOptions,
  StepPropertyType,
  StepSuburb
} from './components/steps';
import { color } from './constants/color';
import { deviceTypeBreakpoint } from './constants/device-type-breakpoints';
import { PropertyOptionsObject } from './enums/property-options';
import { TypesOfStepEnum } from './enums/type-of-step';
import { firebaseFunctions } from './firebase-service';
import { submitCaptchaWithData } from './services/recaptcha';
import { AboutYouFormModel } from './types/about-you-form';
import { OptionalQuestionsModel } from './types/optional-questions';
import { PropertyOptionsModel } from './types/property-options';
import { PropertyTypeModel } from './types/property-type';
import { loadRecaptchaScript, removeRecaptchaScript } from './utils/script-loaders';

const tagManagerArgs = {
  gtmId: 'GTM-56Z5CD5'
};

TagManager.initialize(tagManagerArgs);

const Main = styled(Box)({
  background: color.blackLoop,
  minHeight: '100vh',
  width: '100%'
});

const StepOuterContainer = styled(Box)({
  background: color.whiteLoop
});

const StepContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  min-height: 748px;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 62px;
  width: 100%;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    min-height: auto;
  }
`;

interface AppState {
  aboutUsForm: AboutYouFormModel;
  austrilianState2: string;
  austrilianState: string;
  optionalQuestions: OptionalQuestionsModel;
  propertyOption: PropertyOptionsModel | null;
  propertyType2: PropertyTypeModel | null;
  propertyType: PropertyTypeModel | null;
  suburb2: string;
  suburb: string;
}

const initialAppState: AppState = {
  aboutUsForm: {
    contactNumber: '',
    email: '',
    name: '',
    sellingStatus: ''
  },
  austrilianState2: '',
  austrilianState: '',
  optionalQuestions: PropertyOptionsObject['Buy'].optionalQuestions,
  propertyOption: null,
  propertyType2: null,
  propertyType: null,
  suburb2: '',
  suburb: ''
};

const App = () => {
  const [state, setState] = useState<AppState>(initialAppState);
  const [stack, setStack] = useState<TypesOfStepEnum[]>([TypesOfStepEnum.propertyOptions]);

  const navigate = useNavigate();

  const stateSetter = (updates: Partial<AppState>) =>
    setState((previousState) => ({ ...previousState, ...updates }));

  const activeStep = stack[stack.length - 1];
  const pushStep = (step: TypesOfStepEnum) => setStack((prev) => [...prev, step]);

  const popStep = () => {
    setStack((prev) => {
      const newStack = [...prev];
      newStack.pop();
      return newStack;
    });
  };

  const {
    aboutUsForm,
    austrilianState,
    austrilianState2,
    optionalQuestions,
    propertyOption,
    propertyType,
    propertyType2,
    suburb,
    suburb2
  } = state;

  const handleOnActiveStepChange = (nextStep: TypesOfStepEnum, operation: 'push' | 'pop') => {
    operation === 'push' ? pushStep(nextStep) : popStep();
  };

  const handleJumpOnClickStep = (currentStep: TypesOfStepEnum) => {
    const propertyOptionStep = currentStep === TypesOfStepEnum.propertyOptions && propertyOption;
    const propertyTypeStep = currentStep === TypesOfStepEnum.propertyType && propertyOption;
    const suburbStep = currentStep === TypesOfStepEnum.suburb && propertyOption && propertyType;
    const aboutYouStep =
      currentStep === TypesOfStepEnum.aboutYou && propertyOption && propertyType && suburb;
    const optionalQuestionStep =
      currentStep === TypesOfStepEnum.optionalQuestions &&
      propertyOption &&
      propertyType &&
      suburb &&
      aboutUsForm.contactNumber &&
      aboutUsForm.name &&
      aboutUsForm.email &&
      aboutUsForm.sellingStatus;

    if (propertyOptionStep) {
      setStack([currentStep]);
    } else if (propertyTypeStep) {
      setStack([TypesOfStepEnum.propertyOptions, TypesOfStepEnum.propertyType]);
    } else if (suburbStep) {
      setStack([
        TypesOfStepEnum.propertyOptions,
        TypesOfStepEnum.propertyType,
        TypesOfStepEnum.suburb
      ]);
    } else if (aboutYouStep) {
      setStack([
        TypesOfStepEnum.propertyOptions,
        TypesOfStepEnum.propertyType,
        TypesOfStepEnum.suburb,
        TypesOfStepEnum.aboutYou
      ]);
    } else if (optionalQuestionStep) {
      setStack([
        TypesOfStepEnum.propertyOptions,
        TypesOfStepEnum.propertyType,
        TypesOfStepEnum.suburb,
        TypesOfStepEnum.aboutYou,
        TypesOfStepEnum.optionalQuestions
      ]);
    }
  };

  const handleSubmit = async (optionalQuestionsState: OptionalQuestionsModel) => {
    const quesObj = optionalQuestionsState;
    const message = Object.keys(quesObj).filter((key) => quesObj[key]);
    const hookBody = {
      name: aboutUsForm.name,
      email: aboutUsForm.email,
      contactNumber: aboutUsForm.contactNumber,
      // sellingStatus: aboutUsForm.sellingStatus,
      // australianState2: austrilianState2,
      australianState: austrilianState,
      message: message,
      propertyOption: propertyOption,
      // propertyType2: propertyType2,
      propertyType: propertyType,
      // suburb2: suburb2,
      suburb: suburb
    };
    const sendConveyancingData = httpsCallable<Record<string, any>, Record<string, any>>(
      firebaseFunctions,
      'httpsSendConveyancingData'
    );
    submitCaptchaWithData(async (token) => {
      const response = await sendConveyancingData({ ...hookBody, token });
      const success = response?.data?.status === 'success';
      return { success };
    });
    navigate('/thankyou');
  };

  useEffect(() => {
    const scriptEl = loadRecaptchaScript();
    return () => {
      removeRecaptchaScript(scriptEl);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const renderActiveStep = () => {
    switch (activeStep) {
      case TypesOfStepEnum.propertyOptions:
        return (
          <StepPropertyOptions
            onNextButton={(newPropertyOption) => {
              stateSetter({
                propertyOption: newPropertyOption,
                optionalQuestions:
                  PropertyOptionsObject[newPropertyOption as PropertyOptionsModel].optionalQuestions
              });
              handleOnActiveStepChange(TypesOfStepEnum.propertyType, 'push');
            }}
          />
        );
      case TypesOfStepEnum.propertyType:
        return (
          <StepPropertyType
            onNextButton={(newPropertyType, newPropertyType2) => {
              stateSetter({ propertyType: newPropertyType, propertyType2: newPropertyType2 });
              handleOnActiveStepChange(TypesOfStepEnum.suburb, 'push');
            }}
            onPreviousButton={() =>
              handleOnActiveStepChange(TypesOfStepEnum.propertyOptions, 'pop')
            }
            propertyOption={propertyOption as PropertyOptionsModel}
            propertyType2State={propertyType2}
            propertyTypeState={propertyType}
          />
        );
      case TypesOfStepEnum.suburb:
        return (
          <StepSuburb
            austrilianState2Prop={austrilianState2}
            austrilianStateProp={austrilianState}
            onNextButton={({ newAustrilianState, newAustrilianState2, newSuburb, newSuburb2 }) => {
              stateSetter({
                austrilianState2: newAustrilianState2,
                austrilianState: newAustrilianState,
                suburb2: newSuburb2,
                suburb: newSuburb
              });
              handleOnActiveStepChange(TypesOfStepEnum.aboutYou, 'push');
            }}
            onPreviousButton={() => handleOnActiveStepChange(TypesOfStepEnum.propertyType, 'pop')}
            propertyOption={propertyOption as PropertyOptionsModel}
            suburb2State={suburb2}
            suburbState={suburb}
          />
        );
      case TypesOfStepEnum.aboutYou:
        return (
          <StepAboutYou
            aboutUsFormIntialValues={aboutUsForm}
            onNextButton={(newAboutFormValues) => {
              stateSetter({ aboutUsForm: newAboutFormValues });
              handleOnActiveStepChange(TypesOfStepEnum.optionalQuestions, 'push');
            }}
            onPreviousButton={() => handleOnActiveStepChange(TypesOfStepEnum.suburb, 'pop')}
            propertyOption={propertyOption as PropertyOptionsModel}
          />
        );
      case TypesOfStepEnum.optionalQuestions:
        return (
          <StepOptionalQuestions
            onNextButton={handleSubmit}
            onPreviousButton={() => handleOnActiveStepChange(TypesOfStepEnum.aboutYou, 'pop')}
            optionalQuestionsState={optionalQuestions}
          />
        );
      default:
        break;
    }
  };

  return (
    <Main>
      <Header />
      <ProgressTracker
        activeStep={activeStep}
        stepStack={stack}
        handleJumpOnClick={(currentStep: TypesOfStepEnum) => handleJumpOnClickStep(currentStep)}
      />
      <StepOuterContainer>
        <StepContainer>{renderActiveStep()}</StepContainer>
      </StepOuterContainer>
      <Footer />
    </Main>
  );
};

export default App;
