import { IconProps, IconSvg } from '../icon-props';

interface IconArrowDownUpOwnProps {
  arrowDirectionUp?: boolean;
}

type IconArrowDownProps = IconArrowDownUpOwnProps & IconProps;

export const IconArrowDown = (props: IconArrowDownProps) => {
  const { arrowDirectionUp, ...restProps } = props;

  const transformDegree = arrowDirectionUp ? 180 : 0;

  return (
    <IconSvg
      height="14.875"
      viewBox="0 0 27.75 14.875"
      width="27.75"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${transformDegree})`}
      {...restProps}
    >
      <path
        d="M6567.159,678.894,6554.7,691.355l-12.461-12.461"
        transform="translate(-6540.822 -677.48)"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </IconSvg>
  );
};
