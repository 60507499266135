import styled from 'styled-components';
import { LogoConveyancingLoop } from '../assets/logos/conveyancing-loop';
import { color } from '../constants/color';
import { deviceTypeBreakpoint } from '../constants/device-type-breakpoints';
import { fontFamily } from '../constants/font-family';
import { fontSize } from '../constants/font-size';
import { BASE_URL } from '../constants/url';
import ExternalLink from './external-link';
import { Flex, Text } from './primitives';

const HeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 44px 40px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column;
    padding: 40px 28px;
    row-gap: 20px;
  }
`;

const Title = styled(Text)`
  color: ${color.whiteLoop};
  font-family: ${fontFamily.leagueGothic};
  font-size: ${fontSize.xxm};
  letter-spacing: 3.39px;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    text-align: center;
  }
`;

Title.defaultProps = {
  children: 'CONVEYANCING QUOTE'
};

const Header = () => (
  <HeaderContainer>
    <ExternalLink href={BASE_URL} target="_blank">
      <LogoConveyancingLoop />
    </ExternalLink>
    <Title />
  </HeaderContainer>
);

export { Header };
