import { IconProps, IconSvg } from '../icon-props';

export const IconSign = (props: IconProps) => (
  <IconSvg
    width="208.577"
    height="124.312"
    viewBox="0 0 208.577 124.312"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Path_85"
      data-name="Path 85"
      d="M1234.843,5971.009s-9.406,65.663-19.167,88.911-17.746,39.93-24.135,23.426,6.389-55.9,29.459-76.133,38.865-16.86,38.51,4.081-19.521,69.035-22.538,61.581,4.969-27.685,24.491-45.431,31.589-14.907,29.991-1.42-9.228,35.671-14.374,28.572,12.067-18.456,34.073-27.862,81.812-18.1,81.812-18.1"
      transform="translate(-1187.222 -5968.18)"
      fill="none"
      stroke="#231f20"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="5"
    />
  </IconSvg>
);
