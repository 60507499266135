import styled from 'styled-components';
import {
  IconBuy,
  IconBuyAndSell,
  IconSell,
  IconTransfer
} from '../../assets/icons/property-options';
import { PropertyOptionsObject } from '../../enums/property-options';
import { IconWithLabelModel } from '../../types/icon-with-label';
import { PropertyOptionsModel } from '../../types/property-options';
import { IconWithLabel } from '../icon-with-label';
import { Grid } from '../primitives';
import { StepWrapper } from '../wrappers/step-wrapper';

const IconWithLabelContainer = styled(Grid)`
  align-items: center;
  column-gap: 32px;
  grid-template-columns: repeat(4, minmax(0, 296px));
  row-gap: 32px;
  cursor: pointer;

  @media (max-width: 1328px) {
    grid-template-columns: repeat(2, minmax(0, 296px));
    flex-direction: column;
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(1, minmax(0, 296px));
    flex-direction: column;
  }
`;

const { Buy, BuyAndSell, Sell, Transfer } = PropertyOptionsObject;

interface StepPropertyOptionsProps {
  onNextButton: (newPropertyOption: PropertyOptionsModel) => void;
}

const StepPropertyOptions = (props: StepPropertyOptionsProps) => {
  const { onNextButton } = props;

  const IconWithLabelElements: IconWithLabelModel[] = [
    {
      Icon: IconBuy,
      label: Buy.propertyOptionTitle,
      keyValue: Buy.key
    },
    {
      Icon: () => IconSell({ marginLeft: '30px' }),
      label: Sell.propertyOptionTitle,
      keyValue: Sell.key
    },
    {
      Icon: IconTransfer,
      label: Transfer.propertyOptionTitle,
      keyValue: Transfer.key
    },
    {
      Icon: IconBuyAndSell,
      label: BuyAndSell.propertyOptionTitle,
      keyValue: BuyAndSell.key
    }
  ];

  return (
    <StepWrapper
      subTitle="Do you want to buy, sell, transfer, or buy and sell?"
      title="I WANT TO..."
    >
      <IconWithLabelContainer>
        {IconWithLabelElements.map((elementProps) => (
          <IconWithLabel
            key={elementProps.keyValue}
            {...elementProps}
            onClick={() => onNextButton(elementProps.keyValue as PropertyOptionsModel)}
          />
        ))}
      </IconWithLabelContainer>
    </StepWrapper>
  );
};

export { StepPropertyOptions };
