import styled from 'styled-components';
import { Footer, Header } from './components';
import { Box, Flex } from './components/primitives';
import { StepYourQuote } from './components/steps';
import { color } from './constants/color';
import { deviceTypeBreakpoint } from './constants/device-type-breakpoints';

const Main = styled(Box)({
  background: color.blackLoop,
  minHeight: '100vh',
  width: '100%'
});

const StepOuterContainer = styled(Box)({
  background: color.whiteLoop
});

const StepContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  min-height: auto;
  margin: 0 auto;
  padding-top: 0;
  padding-bottom: 62px;
  width: 100%;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    min-height: auto;
  }
`;

export const ThankYouPage = () => {
  return (
    <Main>
      <Header />
      <StepOuterContainer>
        <StepContainer>
          <StepYourQuote />
        </StepContainer>
      </StepOuterContainer>
      <Footer />
    </Main>
  );
};
