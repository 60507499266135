import styled from 'styled-components';
import { boxShadow } from '../constants/box-shadow';
import { color } from '../constants/color';
import { fontFamily } from '../constants/font-family';
import { fontSize } from '../constants/font-size';
import { IconWithLabelModel } from '../types/icon-with-label';
import { Flex, Text } from './primitives';

interface IconWithLabelContainerProps {
  selected: boolean;
}

const IconWithLabelContainer = styled(Flex)<IconWithLabelContainerProps>`
  align-items: center;
  border: 3px solid ${color.blackLoop};
  border-radius: 3px;
  box-shadow: ${({ selected }) => (selected ? boxShadow.regular : 'none')};
  flex-direction: column;
  height: 280px;
  justify-content: space-between;
  padding-bottom: 40px;
  padding-top: 44px;
  width: 300px;

  :hover {
    box-shadow: ${boxShadow.regular};
  }
`;

const Label = styled(Text)({
  fontFamily: fontFamily.montserratSemiBold,
  fontSize: fontSize.xs,
  textAlign: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'pre-line'
});

const IconWithLabel = (props: IconWithLabelModel) => {
  const { Icon, label, onClick, selected } = props;

  return (
    <IconWithLabelContainer onClick={onClick} selected={Boolean(selected)}>
      <Icon />
      <Label>{label}</Label>
    </IconWithLabelContainer>
  );
};

export { IconWithLabel };
