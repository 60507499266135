import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconBuy = (props: IconProps) => (
  <IconSvg
    height="138.647"
    viewBox="0 0 139.074 138.647"
    width="139.074"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-238.299 -456.386)">
      <g>
        <g>
          <path d="M350.409,595.033H265.264a6.962,6.962,0,0,1-6.954-6.953V527.422H239.8a1.5,1.5,0,0,1-1.06-2.56l68.037-68.036a1.5,1.5,0,0,1,2.121,0l68.037,68.036a1.5,1.5,0,0,1-1.061,2.56H357.362V588.08A6.961,6.961,0,0,1,350.409,595.033ZM243.421,524.422H259.81a1.5,1.5,0,0,1,1.5,1.5V588.08a3.959,3.959,0,0,0,3.954,3.953h85.145a3.958,3.958,0,0,0,3.953-3.953V525.922a1.5,1.5,0,0,1,1.5-1.5h16.39l-64.416-64.415Z" />
        </g>
      </g>
      <g>
        <path
          d="M290.443,571.3c-5.973,0-10.173-1.456-11.917-4.355-1.407-2.338-1.83-6.369,4.141-12.294a2.5,2.5,0,1,1,3.521,3.549c-3.276,3.251-3.867,5.354-3.378,6.168,1.112,1.848,9.083,3.9,26.622-1.556,12.382-3.853,16.247-10.7,15.795-15.4-.527-5.482-6.35-9.493-15.2-10.467-11.2-1.235-18.554-6.192-20.175-13.6-1.586-7.245,2.946-15.263,11.545-20.427,12.3-7.381,25.717-3.7,30.292,1.667,2.494,2.925,2.62,6.435.327,9.158a2.5,2.5,0,0,1-3.825-3.219c.346-.411.989-1.176-.307-2.7-3.032-3.556-13.615-6.807-23.913-.624-6.729,4.041-10.354,9.957-9.235,15.072,1.134,5.178,6.907,8.712,15.839,9.7,13.553,1.494,19.024,8.706,19.626,14.958.832,8.646-6.739,16.753-19.286,20.656C302.946,570.07,295.943,571.3,290.443,571.3Z"
          fill={color.violetLoop}
        />
        <path
          d="M293.44,586.618a2.5,2.5,0,0,1-2.144-3.783c1.158-2.817,5.558-27.916,9.1-48.113,6.594-37.617,8.7-48.834,9.956-50.685a2.5,2.5,0,0,1,4.227,2.664c-1.179,2.794-5.657,28.338-9.258,48.885-7.006,39.965-8.665,48.378-9.862,50.01A2.5,2.5,0,0,1,293.44,586.618Zm-2.014-3.979v0Zm23.127-95.9c-.02.033-.041.066-.064.1A1.128,1.128,0,0,0,314.553,486.743Z"
          fill={color.violetLoop}
        />
        <path
          d="M305.38,578.136a2.544,2.544,0,0,1-.524-.055,2.5,2.5,0,0,1-1.923-2.967c1.091-5.115,14.465-81.406,14.6-82.177a2.5,2.5,0,0,1,4.925.863c-.552,3.148-13.526,77.153-14.636,82.357A2.5,2.5,0,0,1,305.38,578.136Z"
          fill={color.violetLoop}
        />
      </g>
    </g>
  </IconSvg>
);
