import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';

const Link = styled.a({
  color: color.violetLoop,
  cursor: 'pointer',
  fontFamily: fontFamily.montserratSemiBold,
  fontSize: fontSize.xs,
  textDecoration: 'underline'
});

Link.displayName = 'Link';

const LinkCall = styled.a({
  color: color.blackLoop,
  cursor: 'pointer',
  fontFamily: fontFamily.montserratSemiBold,
  fontSize: fontSize.m,
  textDecoration: 'none'
});

LinkCall.displayName = 'LinkCall';

export { Link, LinkCall };
