import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconOffThePlan = (props: IconProps) => (
  <IconSvg
    height="138.034"
    viewBox="0 0 151.379 138.034"
    width="151.379"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-2872.287 -456.415)">
      <path d="M3022.166,594.449H2894.073c-9.21,0-16.158-2.723-19.565-7.665a12.8,12.8,0,0,1-2.211-7.66c0-.024,0-.046,0-.07v-107.3a19.212,19.212,0,0,1,1.4-7.395c2.842-7.46,11.293-8.735,17.665-7.564,4.319.795,8.677,2.871,8.677,5.717V483.35h122.132a1.5,1.5,0,0,1,1.5,1.5v108.1A1.5,1.5,0,0,1,3022.166,594.449Zm-146.883-15.2a9.791,9.791,0,0,0,1.7,5.83c2.789,4.046,9.02,6.367,17.095,6.367h126.593V486.35H2900.034v84.26a1.5,1.5,0,0,1-3,0c-.225-.791-3.852-2.837-8.972-3.085-2.737-.135-9.352.186-11.567,6a17.325,17.325,0,0,0-1.2,5.307v.222A1.6,1.6,0,0,1,2875.283,579.252ZM2887.176,459.4c-3.081,0-8.678.765-10.681,6.022a16.3,16.3,0,0,0-1.2,6.278v97.837c3.692-4.869,10.632-5.65,16.062-4.648a19.045,19.045,0,0,1,5.677,1.926V462.512c-.224-.791-3.85-2.837-8.968-3.086C2887.8,459.414,2887.5,459.4,2887.176,459.4Z" />
      <path
        d="M2941.361,576.474q-2.168,0-4.005-.051c-8.372-.23-11.3-1.1-12.046-3.573-.714-2.363-.775-9.687-.814-21.441-.021-6.243-.048-14.431-.351-17.823l-.127,0c-3.957.085-6.876,0-8.318-1.851a2.875,2.875,0,0,1,.043-3.492c1.755-2.92,10.529-11.893,17.894-18.854,16.387-15.488,18.692-15.085,20.07-14.843,3.624.633,21.564,12.53,21.745,12.65,18.971,12.6,19.614,15.289,19.889,16.436a2.94,2.94,0,0,1-1.075,3.093,11.924,11.924,0,0,1-6.456,1.989c.038.365.08.761.123,1.164,3.416,32.263,2.777,40.495.766,42.927a3.244,3.244,0,0,1-2.219,1.2c-.3.027-14.592,1.291-28.866,1.995C2951.232,576.316,2945.787,576.474,2941.361,576.474Zm-11.4-5.75c4.913,1.6,31.1.492,55.017-1.6.624-2.451.925-10.91-2.019-38.714-.28-2.641-.329-3.135-.329-3.437,0-2.89,3.046-3.112,4.509-3.218a18.565,18.565,0,0,0,2.086-.233c-6.255-5.875-31.023-22.082-36.081-23.94-4.42,1.916-25.119,21.7-31.426,29.026.83.016,1.668,0,2.192-.016a20.964,20.964,0,0,1,2.432.028c2.732.293,2.826,3.3,3,8.775.12,3.826.137,9,.154,14C2929.519,558.4,2929.551,567.758,2929.963,570.724Zm23.473-71.253"
        fill={color.violetLoop}
      />
      <path
        d="M2956.1,557.772c-12.949,0-13.647-1.572-14.217-2.856-1.045-2.352-.692-13.723-.212-22.849a2.5,2.5,0,0,1,.935-1.82c.714-.572,1.6-1.285,14.532-1.218,6,.03,13.077.265,15.35.719a4.312,4.312,0,0,1,2.942,2.386c2.784,5.142,1.865,21,1.187,22.7-.561,1.4-1.045,2.609-16.9,2.907Q2957.764,557.772,2956.1,557.772Zm-9.606-4.78h0Zm-.128-.779c4.2.912,20.8.589,25.715-.222.435-4.2.355-15.083-1-17.411-3.987-.576-19.444-.725-24.518-.3C2946.224,541.56,2946.08,549.473,2946.364,552.213Z"
        fill={color.violetLoop}
      />
      <path
        d="M2959.261,557.744a2.5,2.5,0,0,1-2.5-2.5v-22.28a2.5,2.5,0,0,1,5,0v22.28A2.5,2.5,0,0,1,2959.261,557.744Z"
        fill={color.violetLoop}
      />
      <path
        d="M2965.657,544.163c-4.776,0-11.771-.215-22.084-.787a2.5,2.5,0,0,1,.277-4.993c12.378.687,26.3,1.044,29.315.508a2.5,2.5,0,0,1,4.01,1.8,2.676,2.676,0,0,1-.763,2.073C2975.851,543.334,2975.04,544.163,2965.657,544.163Zm6.532-3.1v0Zm0,0v0Zm0,0v0Zm0,0v0Zm0,0h0Zm0,0Zm0,0"
        fill={color.violetLoop}
      />
    </g>
  </IconSvg>
);
