import styled from 'styled-components';
import { IconDone } from '../../assets/icons/done';
import { color } from '../../constants/color';
import { deviceTypeBreakpoint } from '../../constants/device-type-breakpoints';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { Box, Flex, LinkCall, Text } from '../primitives';

const BodyContainer = styled(Flex)`
  align-self: center;
  flex-wrap: wrap;
  padding: 64px 24px 60px;
  row-gap: 32px;
  max-width: 948px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column;
  }
`;

const PatternContainer = styled(Flex)`
  left: 164px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  position: absolute;
  right: 0;
  text-align: center;
  width: fit-content;
  z-index: 0;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    position: initial;
  }
`;

// const PriceText = styled(Text)({
//   alignItems: 'baseline',
//   display: 'flex',
//   fontFamily: fontFamily.leagueGothic,
//   fontSize: fontSize.xxl,
//   letterSpacing: '9px'
// });

// const QuoteContainer = styled(Flex)({
//   flexDirection: 'column',
//   maxWidth: '460px',
//   rowGap: '16px',
//   width: '100%'
// });

const SpeedUpProcessContainer = styled(Flex)({
  flexDirection: 'column',
  maxWidth: '204px',
  rowGap: '36px',
  width: '100%'
});

const StepYourQuoteContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%'
});

const SubTitle = styled(Text)({
  fontSize: fontSize.s
});

const VioletBg = styled('div')`
  background: ${color.violetLoop};
`;

const ThankYouAreContainer = styled(Flex)`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  display: flex;
  height: 468px;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ThankYouAreText = styled(Text)`
  font-family: ${fontFamily.leagueGothic};
  font-size: ${fontSize.xl};
  letter-spacing: 7px;
  z-index: 1;

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    margin-right: 0;
  }
`;

ThankYouAreText.defaultProps = {
  children: 'THANKS, YOU ARE'
};

const TextIncGST = styled(Text)({
  fontFamily: fontFamily.leagueGothic,
  fontSize: fontSize.l,
  letterSpacing: '3px'
});

TextIncGST.defaultProps = {
  children: 'inc GST'
};

const Title = styled(Text)({
  fontFamily: fontFamily.leagueGothic,
  fontSize: fontSize.xm,
  letterSpacing: '3px',
  textTransform: 'uppercase'
});

const WhatsNextContainer = styled(Flex)`
  flex-direction: column;
  max-width: 312px;
  margin-right: 112px;
  row-gap: 24px;
  width: 100%;

  @media (max-width: 1096px) {
    margin-left: 32px;
  }

  @media (max-width: ${deviceTypeBreakpoint.desktop}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const IconDoneSvg = styled(IconDone)`
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

const StepYourQuote = () => {
  return (
    <StepYourQuoteContainer>
      <VioletBg>
        <ThankYouAreContainer>
          <ThankYouAreText />
          <PatternContainer>
            <IconDoneSvg />
          </PatternContainer>
        </ThankYouAreContainer>
      </VioletBg>
      <BodyContainer>
        <Box>
          <WhatsNextContainer>
            <Title>WHAT’S NEXT?</Title>
            <SubTitle>
              We'll be in touch shortly for a no obligation consultation about your quote. We'll
              also take you through our process from here.
            </SubTitle>
          </WhatsNextContainer>
        </Box>
        <Box>
          <SpeedUpProcessContainer>
            <Title>WANT TO SPEED UP THE PROCESS?</Title>
            <SubTitle>
              Call us on <br />
              <LinkCall href="tel:1300444444">1300 444 444</LinkCall>
            </SubTitle>
          </SpeedUpProcessContainer>
        </Box>
      </BodyContainer>
    </StepYourQuoteContainer>
  );
};

export { StepYourQuote };
