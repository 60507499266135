import { color } from '../../../constants/color';
import { IconProps, IconSvg } from '../icon-props';

export const IconSell = (props: IconProps) => (
  <IconSvg
    height="140.046"
    viewBox="0 0 135.554 140.046"
    width="135.554"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="translate(-587.282 -464.696)">
      <path d="M634.7,604.742c-30.392,0-45.452-12.964-47.341-40.666-1.67-24.5,25.789-55.108,30.834-60.509-1.767-3.415-7.666-15.044-10.058-22.563-1.852-5.818-.4-9.194,1.151-11,2.05-2.395,5.532-3.414,9.549-2.793,2.268.349,4.173,1.655,6.19,3.037,3.135,2.148,6.377,4.373,11.731,3.81,3.813-.4,6.151-2.475,8.627-4.678,3.169-2.818,6.449-5.729,12.447-4.313a7.62,7.62,0,0,1,5.327,4.235c2.576,5.431-.08,14.7-3.136,20.977-2.77,5.693-6.168,11.323-7.546,13.551a158.508,158.508,0,0,1,24.3,34.821c10.885,21.77,7.08,40.534-.111,50.835-7.466,10.7-18.269,14.825-39.857,15.236h0C636.095,604.735,635.39,604.742,634.7,604.742Zm2.08-1.521h0ZM616.57,470.031a6.4,6.4,0,0,0-5.009,1.923c-1.487,1.736-1.689,4.628-.571,8.14,2.689,8.453,10.274,22.884,10.35,23.029a1.5,1.5,0,0,1-.248,1.741c-.325.336-32.453,33.943-30.744,59.009,1.825,26.751,16.152,38.443,46.4,37.848h0c20.474-.389,30.625-4.172,37.454-13.953,6.7-9.594,10.185-27.182-.112-47.776a154.118,154.118,0,0,0-24.576-34.919,1.5,1.5,0,0,1-.2-1.861c.045-.071,4.532-7.117,8-14.247,2.888-5.934,5.1-14.217,3.123-18.378a4.594,4.594,0,0,0-3.306-2.6c-4.482-1.063-6.813,1.012-9.764,3.636-2.6,2.307-5.536,4.923-10.309,5.42-6.454.669-10.492-2.1-13.738-4.321-1.8-1.234-3.358-2.3-4.951-2.547A11.9,11.9,0,0,0,616.57,470.031Z" />
      <path
        d="M608.487,520.057a2.488,2.488,0,0,1-1.405-.433c-3.29-2.242-4.274-6.173-2.508-10.018,3.01-6.551,13.181-11.632,26.1-8.151,19.361,5.22,64.942,4.7,78.354-11.761a2.5,2.5,0,0,1,3.876,3.158c-15.538,19.069-63.331,18.875-83.532,13.431-10.907-2.939-18.413,1.393-20.258,5.41-.759,1.651-.5,2.93.779,3.8a2.5,2.5,0,0,1-1.41,4.566Z"
        fill={color.violetLoop}
      />
      <path
        d="M658.587,515.755a2.5,2.5,0,0,1-1-4.793c12.549-5.458,18.433-14.438,24.123-23.123,7.939-12.119,15.444-23.549,38.719-22.677a2.5,2.5,0,0,1-.19,5c-20.463-.779-26.587,8.576-34.347,20.421-5.809,8.866-12.392,18.916-26.312,24.968A2.488,2.488,0,0,1,658.587,515.755Z"
        fill={color.violetLoop}
      />
      <g>
        <path d="M635.234,577.04c-.182,0-.367,0-.554-.007a22.3,22.3,0,0,1-15.812-7.023,1.5,1.5,0,1,1,2.294-1.933,19.635,19.635,0,0,0,13.594,5.956c4.975.153,7.734-1.752,9.174-3.349a8.275,8.275,0,0,0,2.116-6.495c-.578-4.462-2.736-7.443-12.461-10.415-9.43-2.882-13.043-6.966-12.884-14.564.106-5.107,4.246-10.756,11.952-11.673,8.02-.963,14.924,4.409,15.214,4.637a1.5,1.5,0,0,1-1.856,2.357c-.07-.054-6.243-4.82-13-4.016-6.286.748-9.231,5.064-9.308,8.757-.112,5.377,1.57,8.823,10.761,11.633,9.907,3.027,13.718,6.4,14.561,12.9a11.318,11.318,0,0,1-2.864,8.891C644.353,574.7,641.006,577.04,635.234,577.04Z" />
        <path d="M634.2,581.3a1.5,1.5,0,0,1-1.5-1.5v-56.08a1.5,1.5,0,0,1,3,0V579.8A1.5,1.5,0,0,1,634.2,581.3Z" />
      </g>
    </g>
  </IconSvg>
);
