import { ReactNode } from 'react';
import styled from 'styled-components';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { ButtonNext, ButtonPrevious, Flex, Text } from '../primitives';
import { Button } from '../primitives/button';
import { ButtonContainer } from '../primitives/button-container';

const ButtonYourQuote = styled(Button).attrs({ type: 'button' })({
  marginTop: '32px',
  marginBottom: '36px',
  width: '240px'
});

ButtonYourQuote.defaultProps = {
  children: 'Just get my quote'
};

const ChildrenContainer = styled(Flex)({
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%'
});

const StepChildrenContainer = styled(Flex)({
  flexDirection: 'column',
  marginTop: '100px',
  width: '100%'
});

const StepWrapperContainer = styled(ChildrenContainer)({
  padding: '0 24px'
});

const SubTitle = styled(Text)({
  fontSize: fontSize.s,
  letterSpacing: 0,
  marginTop: '16px',
  textAlign: 'center'
});

const TextItemsContainer = styled(Flex)({
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '52px'
});

const Title = styled(Text)({
  fontFamily: fontFamily.leagueGothic,
  fontSize: fontSize.l,
  letterSpacing: '3.5px',
  textAlign: 'center'
});

interface StepWrapperProps {
  children2?: ReactNode;
  children?: ReactNode;
  nextButtonDisabled?: boolean;
  onGetYourQuoteButton?: () => void;
  onNextButton?: () => void;
  onPreviousButton?: () => void;
  subTitle?: string;
  title2?: string;
  title: string;
}

const StepWrapper = (props: StepWrapperProps) => {
  const {
    children,
    children2,
    nextButtonDisabled,
    onGetYourQuoteButton,
    onNextButton,
    onPreviousButton,
    subTitle,
    title,
    title2
  } = props;

  return (
    <StepWrapperContainer>
      <TextItemsContainer>
        <Title>{title}</Title>
        {onGetYourQuoteButton && <ButtonYourQuote onClick={onGetYourQuoteButton} />}
        {subTitle && <SubTitle> {subTitle}</SubTitle>}
      </TextItemsContainer>
      <ChildrenContainer>{children}</ChildrenContainer>
      {children2 && (
        <StepChildrenContainer>
          <TextItemsContainer>
            <Title>{title2}</Title>
            <SubTitle> {subTitle}</SubTitle>
          </TextItemsContainer>
          <ChildrenContainer>{children2}</ChildrenContainer>
        </StepChildrenContainer>
      )}
      {(onPreviousButton || onNextButton) && (
        <ButtonContainer>
          {onPreviousButton && <ButtonPrevious onClick={onPreviousButton} />}
          {onNextButton && (
            <ButtonNext disabled={nextButtonDisabled} onClick={onNextButton} type="button" />
          )}
        </ButtonContainer>
      )}
    </StepWrapperContainer>
  );
};

export { StepWrapper };
