import styled from 'styled-components';
import { IconSign } from '../assets/icons/inputs';
import { SiteLogo } from '../assets/icons/inputs/site-logo';
import WhiteGrain from '../assets/images/grain_soft_contrasted_white.png';
import { color } from '../constants/color';
import { deviceTypeBreakpoint } from '../constants/device-type-breakpoints';
import { fontFamily } from '../constants/font-family';
import { generalPagesLink, legalPagesLink } from '../enums/footer-links';
import { Box, Text } from './primitives';
import Richtext from './primitives/Richtext';

const FooterWrapper = styled('footer')`
  background: ${color.violetLoop};
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 820px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media (max-width: ${deviceTypeBreakpoint.tab}) {
    text-align: center;
  }
`;

const FooterContainer = styled(Box)`
  margin-left: 120px;
  margin-right: 120px;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 820px) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 0;
  }
  @media (max-width: ${deviceTypeBreakpoint.tab}) {
    margin: 0;
  }
`;

const FooterText = styled(Text)`
  color: ${color.blackLoop};
  font-size: 20px;
  margin-bottom: 20px;
  @media (max-width: 820px) {
    font-size: 16px;
  }
`;

const Img = styled('img')`
  @media (max-width: 820px) {
    opacity: 0.5;
  }
`;

const FooterGrid = styled('div')`
  display: grid;
  grid-template-columns: 46% 20% 20%;
  gap: 4%;
  @media (max-width: 820px) {
    display: grid;
    grid-template-columns: 60% 30%;
    gap: 36px;
  }
  @media (max-width: ${deviceTypeBreakpoint.tab}) {
    grid-template-columns: 1fr;
  }
`;

const CopyRightText = styled(Text)`
  font-size: 14px;
  padding-top: 8px;
`;

const FooterDescription = styled(Richtext)`
  font-size: 14px;
  margin-top: 42px;
  @media (max-width: 820px) {
    font-size: 12px;
    marin-top: 0;
  }
`;

const DecorativeWrapper = styled('figure')`
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  height: 100%;
  width: 100%;
`;

const DecorativeContainer1 = styled(Box)`
  position: relative;
  bottom: 0px;
  left: 0px;
  width: 100%;
  transform: translate(-35%, 65%);
  height: auto;
  @media (max-width: 820px) {
    transform: translate(-110%, 72%);
  }
`;

const SignSvg = styled(IconSign)`
  position: absolute;
  top: -62px;
  right: 8%;
  @media (max-width: 820px) {
    height: 72px;
    width: 124px;
    left: 50%;
    top: -36px;
    transform: translateX(-50%);
  }
`;

const RightWhiteGrainImg = styled('img')`
  position: absolute;
  top: -424px;
  right: -500px;
  height: 900px;
  width: auto;
  @media (max-width: 820px) {
    opacity: 0.5;
  }
  @media (max-width: ${deviceTypeBreakpoint.tab}) {
    display: none;
  }
`;

const Link2 = styled('a')`
  font-size: 20px;
  padding-left: 4px;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 820px) {
    font-size: 16px;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const LinkFlex = styled(Box)`
  display: flex;
  @media (max-width: ${deviceTypeBreakpoint.tab}) {
    justify-content: center;
  }
`;

const Link1 = styled('a')`
  color: ${color.blackLoop};
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const FooterHeading = styled(Text)`
  font-family: ${fontFamily.leagueGothic};
  font-size: 30px;
  letter-spacing: 4px;
  font-weight: 300;
`;

const contactHeading = 'GET IN TOUCH';
const CLDescription = `<p>Conveyancing Loop Pty Ltd ACN 655 557 924 is proudly part of the Listing Loop Group of brands and is operated by Listing Loop Australia Pty Ltd ACN 628 994 276 (Listing Loop)</p>
</br>
<p>Listing Loop have partnered with licensed conveyancing firm Bond Conveyancing ACN 658 453 500 to provide Conveyancing Services that may include but are not limited to, sale conveyancing, purchase conveyancing, sale contract packaging, contract summary, and contract review services.</p>`;


const Footer = () => {
  return (
    <FooterWrapper>
      <DecorativeWrapper>
        <DecorativeContainer1>
          <Img src={WhiteGrain} alt="decoratives" />
        </DecorativeContainer1>
        <RightWhiteGrainImg src={WhiteGrain} />
      </DecorativeWrapper>
      <FooterContainer>
        <FooterGrid>
          <Box>
            <SiteLogo style={{ height: '66px', width: '298px', color: color.whiteLoop }} />
            <Box style={{ marginTop: '30px' }}>
              <FooterHeading>{contactHeading}</FooterHeading>
              <FooterText style={{ marginTop: '20px' }}>
                Weekdays 9:00 am to 5:30 pm AEST
              </FooterText>
              <LinkFlex>
                <FooterText>Call: </FooterText>
                <Link2 href="tel:1300695667" style={{ color: color.blackLoop }}>
                  1300 695 667
                </Link2>
              </LinkFlex>
              <LinkFlex>
                <FooterText>Email: </FooterText>
                <Link2
                  href="mailto:info@conveyancingloop.com.au"
                  style={{ color: color.blackLoop }}
                >
                  info@conveyancingloop.com.au
                </Link2>
              </LinkFlex>
            </Box>
            <CopyRightText>Copyright © Conveyancing Loop {new Date().getFullYear()}</CopyRightText>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            {legalPagesLink.map((link) => {
              return (
                <Box style={{ marginBottom: '20px' }} key={link.label}>
                  <Link1 href={link.href} target="_blank">
                    {link.label}
                  </Link1>
                </Box>
              );
            })}
          </Box>
          <Box style={{ marginTop: '20px' }}>
            {generalPagesLink.map((link) => {
              return (
                <Box style={{ marginBottom: '20px' }} key={link.label}>
                  <Link1 href={link.href} target="_blank">
                    {link.label}
                  </Link1>
                </Box>
              );
            })}
          </Box>
        </FooterGrid>
        <FooterDescription text={CLDescription} />
      </FooterContainer>
      <SignSvg />
    </FooterWrapper>
  );
};

export { Footer };
