import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import './assets/styles/styles.css';
import { ThankYouPage } from './ThankYouPage';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': 'Co4o23bD5TVMYRZm7419'
  },
  uri: 'https://api.listingloop.com.au/graphql'
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  </ApolloProvider>
);
