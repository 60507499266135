import styled from 'styled-components';
import { fontFamily } from '../../constants/font-family';
import { fontSize } from '../../constants/font-size';
import { Flex, Text } from '../primitives';

const Wrapper = styled(Flex)({
  alignItems: 'center',
  height: '200px',
  justifyContent: 'center',
  maxWidth: '600px',
  paddingTop: '62px',
  width: '100%'
});

const Title = styled(Text)({
  fontFamily: fontFamily.leagueGothic,
  fontSize: fontSize.l,
  textAlign: 'center'
});

Title.defaultProps = {
  children:
    'Thank you for your purchase, a member of the Conveyancing Loop team will be in touch with you shortly'
};

const StepThankYou = () => {
  return (
    <Wrapper>
      <Title />
    </Wrapper>
  );
};

export { StepThankYou };
