import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { color } from '../constants/color';
import { fontFamily } from '../constants/font-family';
import { fontSize } from '../constants/font-size';
import { stepperItems, TypesOfStepEnum } from '../enums/type-of-step';
import { TypeOfStepModel } from '../types/type-of-step';
import { Flex, Text } from './primitives';

const Step = styled(Text)`
  align-items: center;
  background-color: ${color.whiteLoop};
  border-bottom: 2px solid;
  border-color: ${color.blackLoop};
  border-top: 2px solid;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-family: ${fontFamily.montserratSemiBold};
  font-size: ${fontSize.xxs};
  height: 52px;
  justify-content: center;
  min-width: 220px;
  padding: 20px;
  position: relative;
  overflow-y: clip;
  &[data-visited='true'] {
    &:after {
      background: ${color.violetLoop};
    }
    background-color: ${color.violetLoop};
    border-color: ${color.violetLoop};
  }
  :after {
    background: transparent;
    border-right: 4px solid ${color.blackLoop};
    border-top: 4px solid ${color.blackLoop};
    content: '';
    height: 56px;
    position: absolute;
    right: -6px;
    top: -3px;
    transform: translateX(20px) rotate(45deg) scale(0.6) skew(15deg, 15deg);
    width: 52px;
    z-index: 1;
  }

  :last-child {
    border: 2px solid ${color.violetLoop};
    border-left: none;
    color: ${color.violetLoop};

    :after {
      content: none;
    }
  }
`;

const ProgressTrackerContainer = styled(Flex)({
  backgroundColor: color.whiteLoop,
  overflowX: 'auto',
  overflowY: 'hidden',
  paddingBottom: '4px'
});

interface ProgressTrackerProps {
  activeStep: TypeOfStepModel;
  stepStack: TypesOfStepEnum[];
  handleJumpOnClick: (currentStep: TypesOfStepEnum) => void;
}

const ProgressTracker = (props: ProgressTrackerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { activeStep, stepStack, handleJumpOnClick } = props;
  const prevStep = stepStack[stepStack.length - 2];
  const currentProgress = prevStep ? stepperItems[prevStep].progress : 0;

  useEffect(() => {
    if (containerRef.current) {
      const x = (containerRef.current.scrollWidth / 100) * currentProgress;
      containerRef.current.scrollTo(x, 0);
    }
  }, [activeStep, currentProgress]);

  return (
    <ProgressTrackerContainer ref={containerRef}>
      {Object.keys(stepperItems).map((item, i) => {
        const { label } = stepperItems[item as TypeOfStepModel];
        return (
          <Step
            key={label}
            data-visited={`${stepStack.length >= i + 1}`}
            onClick={() => handleJumpOnClick(item as TypesOfStepEnum)}
          >
            {label}
          </Step>
        );
      })}
    </ProgressTrackerContainer>
  );
};

export { ProgressTracker };
