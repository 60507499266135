import styled from 'styled-components';
import { color } from '../../constants/color';
import { fontSize } from '../../constants/font-size';
import { Text } from './text';

const TextContainer = styled(Text)({
  alignItems: 'center',
  border: `2px solid ${color.blackLoop}`,
  borderRadius: '4px',
  display: 'flex',
  fontSize: fontSize.s,
  height: '60px',
  justifyContent: 'center',
  maxWidth: '624px',
  padding: '0 28px',
  width: '100%'
});

TextContainer.displayName = 'Text Container';

export { TextContainer };
