import { SuburbSuggestions } from './interfaces/generated/SuburbSuggestions';
import { SuburbInfo } from './types/suburb-info';

interface convertToGqlSpecializationAreasProps {
  suggestionsFromQuery: SuburbSuggestions;
}

const convertToGqlSpecializationAreas = (
  props: convertToGqlSpecializationAreasProps
): SuburbInfo[] => {
  const { suggestionsFromQuery } = props;

  const { allRegions, allSpecializationAreas } = suggestionsFromQuery;
  const suburbSuggestions = allSpecializationAreas.SpecializationAreas || [];
  const regionSuggestions = allRegions?.Regions || [];

  let regionSuggestionFormated: SuburbInfo[] = [];
  let suburbSuggestionFormated: SuburbInfo[] = [];

  if (regionSuggestions.length) {
    regionSuggestionFormated = regionSuggestions.map(({ id, regionDisplayName, state }) => ({
      id,
      postcode: null,
      state: state as string,
      suburbDisplayName: regionDisplayName
    }));
  }

  if (suburbSuggestions.length) {
    suburbSuggestionFormated = suburbSuggestions.map(
      ({ id, postcode, state, suburbDisplayName }) => ({
        id,
        postcode,
        state: state as string,
        suburbDisplayName
      })
    );
  }

  const clubAllSuggestions = [...suburbSuggestionFormated, ...regionSuggestionFormated];

  return clubAllSuggestions;
};

export { convertToGqlSpecializationAreas };
